import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
    study: Yup.object().shape({
        name: Yup.string().required("Study name is required"),
        description: Yup.string().required("Study description is required")
    }),
    seqType: Yup.string().required("Sequencing type is required"),
    jobType: Yup.string().required("Job type is required")
        .when(('seqType'), {
            is: (val) => val === "bulk",
            then: () => Yup.string().matches(/(count|dge)/, "Job type for bulk sequencing must be count or differential gene expression")
        })
        .when(('seqType'), {
            is: (val) => val === "single-cell",
            then: () => Yup.string().matches(/(count|aggr)/, "Job type for scRNA sequencing must be count or aggregation")
        }),
    samples: Yup.array()
        .test(
            "validate-samples",
            (value, context) => validateSamples(value, context)
        ),
    referenceGenome: Yup.object().shape({
        publisher: Yup.string(),
        annotations: Yup.string(),
        species: Yup.string()
            .test(
                "validate-ref-species",
                (value, context) => validateRefSpecies(value, context)
            )
    }),
    jobOptions: Yup.object().shape({
        runBatchCorrection: Yup.boolean().required(),
        dge: Yup.object().shape({
            comparisons: Yup.array()
                .when(('jobType'), {
                    is: (val) => val === "dge",
                    then: () => Yup.array().min(1, "Must have at least one comparison for dge")
                })
        })
    })
});

const errors = {}

function validateSamples(samples, context) {
    const parent = context.parent;
    const studySeqType = parent.seqType;
    const validSeqType = ["bulk", "single-cell"];
    let minMessage = (units, jobType) => `A minimum of 2 ${units} are required for ${jobType}`;

    // Check sample array length
    if (samples.length < 1) {
        return context.createError({message: "A minimum of one sample is required"})
    }

    const seqType = samples[0].seqType;
    const species = samples[0].species;

    for (const sample of samples) {
        const sampleSeqType = sample.seqType;
        const sampleTitle = sample.sampleTitle;

        if (parent.dataSource === "sra") {
            // Check if sample seq type is supported
            if (!validSeqType.includes(sampleSeqType)) {
                return context.createError({message: `Cannot process sequence type for sample ${sampleTitle}`})
            }

            // Check if different seq types have been selected
            if (sampleSeqType !== seqType) {
                return context.createError({message: "Cannot process different sequence types"})
            }
            // Check if sample seq type is not the same as study seq type
            if (sampleSeqType !== studySeqType) {
                return context.createError({
                    message: `Seq type for sample ${sampleTitle} is not the same as study seq type`
                })
            }

            // Check if scRNA library is supported i.e., 10x
            if (sampleSeqType === "single-cell" && sample.library !== "10x") {
                return context.createError({
                    message: `${sampleTitle} library type ${sample.library} is not supported`
                })
            }

            // Check if species has been entered
            if (sample.species === undefined) {
                return context.createError({message: `Missing species for ${sampleTitle}`})
            }

            // Check if different species have been selected
            if (sample.species !== species) {
                return context.createError({message: `Cannot process different sample species: ${sampleTitle}`})
            }
        }
    }

    if (parent.jobType === "dge") {
        if (samples.length < 2) {
            return context.createError({message: minMessage("samples", "dge")});
        }

        let groups = new Set();
        for (const sample of samples) {
            if (!sample.hasOwnProperty("group") || sample.group === "") {
                return context.createError({message: `Missing group for sample ${sample.sampleTitle}`});
            }
            groups.add(sample.group);
        }

        if (parent.jobOptions.runBatchCorrection === true) {
            let mappings = {};
            for (const sample of samples) {
                const sourceStudy = sample.sourceStudyId;
                if (sourceStudy in mappings) {
                    if (sample.group !== mappings[sourceStudy]['group']) {
                        return context.createError({message: `Cannot process groups that span multiple studies`});
                    }
                } else {
                    mappings[sourceStudy] = {'group': sample.group};
                }
            }
        }
    }

    if (parent.jobType === "aggr") {
        if (samples.length < 2) {
            return context.createError({message: minMessage("groups", "aggregation")});
        }
    }
    return true;
}

function validateRefSpecies(refSpecies, context) {
    const contextFromValues = context.from[context.from.length - 1].value;
    const samples = contextFromValues.samples;
    const dataSource = contextFromValues.dataSource;
    if (samples.length > 0 && dataSource !== 'fileUpload' && dataSource !== 'mixed') {
        for (const sample of samples) {
            const sampleSpecies = sample.species;
            if (sampleSpecies !== refSpecies) {
                return context.createError({
                    message: "Selected reference species is not same as samples species"
                })
            }
        }
    }
    return true;
}

export {ValidationSchema}