import React, {useEffect} from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate} from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import GoogleButton from "react-google-button";
import {Tooltip} from "@mui/material";
import bifrostImg from "../images/bifrost.jpg"
import {auth} from "../functions/firebaseFunctions";
import { useSignInWithGoogle} from "react-firebase-hooks/auth";
const PREFIX = 'Login';

const classes = {
    container: `${PREFIX}-container`,
    paper: `${PREFIX}-paper`,
    avatar: `${PREFIX}-avatar`,
    form: `${PREFIX}-form`,
    submit: `${PREFIX}-submit`,
    signInButton: `${PREFIX}-signInButton`,
    customError: `${PREFIX}-customError`,
    progess: `${PREFIX}-progess`,
    helpText: `${PREFIX}-helpText`,
    logo: `${PREFIX}-logo`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.container}`]: {
        backgroundImage: `url(${bifrostImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100vw',
        height: '100vh',
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    [`& .${classes.paper}`]: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // backgroundImage: `url(${bifrostImg})`,
        // width: '100%',
        // height: '100%'
    },

    [`& .${classes.avatar}`]: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.dark
    },

    [`& .${classes.form}`]: {
        width: '100%',
        marginTop: theme.spacing(1)
    },

    [`& .${classes.submit}`]: {
        margin: theme.spacing(3, 0, 2)
    },

    [`& .${classes.signInButton}`]: {
        margin: theme.spacing(5, 0, 2)
    },

    [`& .${classes.customError}`]: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },

    [`& .${classes.progess}`]: {
        position: 'absolute'
    },

    [`& .${classes.helpText}`]: {
        textAlign: 'center',
    },

    [`& .${classes.logo}`]: {
        height: '70%',
        width: '100%',
        borderRadius: '50%',
        marginBottom: theme.spacing(1)
    }
}));

const scopes = [
    'email',
    'profile',
    "https://www.googleapis.com/auth/drive",
    "https://www.googleapis.com/auth/drive.install",
    "https://www.googleapis.com/auth/drive.file",
    "https://www.googleapis.com/auth/drive.appdata"
]

function Login() {
    const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);
    const navigate = useNavigate();

    useEffect(() => {
        // console.log("use effect login")
        if (loading) {
            return;
        }
        if (user) {
            // console.log("user is logged in");
            navigate("/");
        } else {
            // console.log("user not logged in")
        }
    }, [user, loading, navigate]);


    return (
        <Root className={classes.container}>
            <Container component="main">
                <CssBaseline/>
                <div className={classes.paper}>
                    {/*<img src={bifrostImg} alt="aTyr Logo" className={classes.logo}/>*/}
                    <Typography component="h1" variant="h1">
                        Bifröst
                    </Typography>
                    <Typography component="h2" variant="h4">
                       aTyr Pharma's Next Gen Sequencing Informatics Platform
                    </Typography>
                    <GoogleButton className={classes.signInButton}
                                  onClick={() => signInWithGoogle(scopes)}
                    />
                    <Tooltip title="Click Sign In with Google button and use aTyr credentials.">
                        <HelpOutlineOutlinedIcon fontSize="large"/>
                    </Tooltip>
                </div>
            </Container>
        </Root>
    );
}

export default Login;
