import {useState} from "react";
import {Field} from "formik";
import {TextField} from "formik-mui";
import {FileGrid} from "./fileInteractions/fileGrid";
import {getSraStudies} from "../functions/sra";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const StudyRunTable = (props) => {
    const prefix = "PRJNA";
    const {setFieldValue, setStudy, setRows, setColumns, setLookupDone, setLookupError, values, uploading} = props;
    const [studyIdError, setStudyIdError] = useState(false);
    const initialCols = [
        {
            field: 'sampleTitle', headerName: "Sample Title", width: 350
        },
        {
            field: 'seqType', headerName: "Sequencing Type", width: 150
        },
        {
            field: 'libraryStrategy', headerName: "Library Strategy", width: 150
        },
        {
            field: 'library', headerName: "Library", width: 150
        },
        {
            field: 'species', headerName: "Species", width: 150
        }
    ]

    function resetRowsAndColumns() {
        setRows([]);
        setColumns([]);
    }

    async function handleStudyInputChange(event) {
        if (values.dataSource === "fileUpload" || values.dataSource === "mixed") {
            setFieldValue("dataSource", "mixed");
        } else {
            setFieldValue("dataSource", "sra");
        }
        setFieldValue("currentDataSource", "sra");
        const newText = event.target.value.toUpperCase();
        if (newText.length >= 5) {
            const match = newText.startsWith(prefix);
            if (!match) {
                setStudyIdError(true);
                setLookupDone(true);
            } else if (match && newText.length >= 10) {
                setLookupError("");
                setStudyIdError(false);
                setLookupDone(false);
                const study = await getSraStudies(newText)
                    .then((result) => {
                        return result;
                    })
                    .catch((error) => {
                        console.log(error.message);
                        setLookupError(`Error finding study ${newText}`);
                        setLookupDone(true);
                    });
                const metaRows = study.samples.map((studyMeta, i) => {
                    studyMeta["id"] = i;
                    return {...studyMeta, ...studyMeta.metadata};
                });
                const metaColumns = initialCols.concat(Object.keys(study.samples[0].metadata).map((tag) => {
                    return {
                        field: tag,
                        headerName: tag,
                        width: 150
                    };
                }));
                setLookupError("")
                setColumns(metaColumns);
                setRows(metaRows);
                const selectedStudy = {
                    "name": newText,
                    "description": study.studyDescription,
                    "title": study.studyTitle
                };
                setFieldValue('study', selectedStudy);
                setStudy(selectedStudy);
                setLookupDone(true);
            }
        } else if (newText.length < 10) {
            setStudyIdError(false);
            setLookupError(null);
            resetRowsAndColumns();
        }
    }

    return <>
        <Field
            component={TextField}
            label="BioProject Identifier"
            name="study.sra"
            color="secondary"
            variant="outlined"
            disabled={uploading}
            helperText={`Must start with ${prefix}`}
            error={studyIdError}
            onChange={async (e) => {
                await handleStudyInputChange(e)
            }}
            multiline
            sx={{mb: 2}}
        />
    </>
}

export {StudyRunTable}