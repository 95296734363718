import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export const AsgardAlert = (props) => {
    const {severity, title, text, warningMessage} = {...props.content};
    return (
        <Alert severity={severity}>
            <AlertTitle>{title}</AlertTitle>
            {text}
            {warningMessage && <><br /><b>{warningMessage}</b></>}
        </Alert>
    )
}