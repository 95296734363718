import React, {useEffect, useState} from "react";
import axios from "axios";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Field, Form, Formik} from "formik";
import {TextField, Select} from "formik-mui";
import {MiniLoading} from "../loading";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import {FormHelperText} from "@mui/material";
import fileDownload from "js-file-download";
import dayjs from 'dayjs';

const apiUrl = process.env.REACT_APP_API_BALDR_URL;

export const Genesight = (props) => {
    const {user} = props;
    const {accessToken} = {...user};
    const idProviderMapping = [
        {label: "Ensembl", value: "ENSEMBLID", key: "ensembl"},
        {label: "Entrez (NCBI)", value: "ENTREZID", key: "entrez"},
        {label: "RefSeq", value: "REFSEQ", key: "refseq"},
        {label: "Gene Symbol", value: "SYMBOL", key: "symbol"}
    ];
    const [error, setError] = useState(null);
    const [gseaGeneSets, setGseaGeneSets] = useState([]);

    const getGeneSets = async () => {
        return axios.get(apiUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            }
        }).then((result) => {
            return result.data.gene_sets;
        });
    }

    const handleGeneListInput = (event) => {
        let genes = [];
        const targetValue = event.target.value;
        genes = targetValue.split(/[,\n]/);
        return genes
    }

    // Form submission handler
    const submitGenesightHandler = async (data) => {
        console.log(data)
        let requestData = {
            genes: data.genes.join(','),
            is_gene_list: true,
            provider: data.idProvider,
            species: data.species,
            is_bifrost_output: false,
            // enrichment_name: data.enrichmentName
        };

        const response = await axios.post(apiUrl, requestData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + accessToken
            }, responseType: 'blob'
        }).then((response) => {
            return response;
        }).catch((error) => {
            console.log(error);
            setError(error);
        });
        console.log(response);
        const fileName = data.enrichmentName === ""
            ? `genesight-${dayjs().format('YYYY-MM-DD')}.zip`
            : data.enrichmentName + ".zip";
        fileDownload(response.data, fileName);
    }

    const validationSchema = Yup.object().shape({
        geneListInput: Yup.string()
            .required("Gene list is required")
            .test(
                "either-comma-or-newline",
                "Gene list must contain either a comma or newline, but not both",
                (value) => {
                    if (!value) return true; // Allow empty strings
                    const hasComma = value.includes(',');
                    const hasNewline = value.includes('\n');
                    return !(hasComma && hasNewline);
                }
            ),
        idProvider: Yup.string().required("Gene ID type is required"),
        species: Yup.string().required("Species is required")
    });

    useEffect(() => {
        async function getData() {
            const geneSets = await getGeneSets();
            setGseaGeneSets(geneSets);
        }
        if (gseaGeneSets.length === 0) getData();
    }, []);

    return (
        <Grid padding={2}>
            <Toolbar/>
            <Box sx={{mb: 2}}>
                <Typography variant="h4" noWrap sx={{mb: 2}}>Genesight</Typography>
                <Typography noWrap>
                    Genesight allows users to perform enrichment analysis on a list of genes.
                </Typography>
            </Box>
            <Box sx={{mb: 2}}>
                <Typography variant="h5" gutterBottom>Output</Typography>
                <Typography>
                    Genesight outputs a zip file containing the tabular data, barplot and dotplot (except GO) for the following:
                    <ul>
                        <li>GO Enrichment</li>
                        <li>KEGG Enrichment</li>
                        <li>Reactome Enrichment</li>
                    </ul>
                </Typography>
                <Typography variant="h5" sx={{mb: 2}}>Input</Typography>
                <Typography>Input a list of rank ordered genes, select an ID type and species.</Typography>
            </Box>

            <Box sx={{mt: 2}}>

                <Formik
                    initialValues={{
                        geneListInput: "",
                        species: "",
                        idProvider: "",
                        idProviderNotConfirmed: true,
                        isBifrostOutput: false,
                        isGeneList: true,
                        enrichmentName: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values, {setSubmitting}) => {
                        await submitGenesightHandler(values).then((result) => {
                                console.log(result);
                            }).catch((error) => {
                                console.log(error)
                            });
                        setSubmitting(false);
                    }}
                >
                    {({
                          submitForm,
                          isSubmitting,
                          values,
                          setFieldValue,
                          handleChange,
                          errors,
                          validateField
                      }) => (
                        <Form>
                            <Grid container spacing={2} flexDirection="column">
                                <Grid sm={8}>
                                    <FormControl sx={{minWidth: '100%'}}>
                                        <Field
                                            component={TextField}
                                            label="Gene List"
                                            name="geneListInput"
                                            color="secondary"
                                            variant="outlined"
                                            helperText="Paste list of ranked genes comma or newline separated"
                                            multiline
                                            rows={10}
                                            disabled={isSubmitting}
                                            onChange={(e) => {
                                                setFieldValue('genes', handleGeneListInput(e));
                                                handleChange(e);
                                            }}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Confirm id provider */}
                                <Grid sm={6}>
                                    <FormControl sx={{minWidth: 200}}>
                                        <Field
                                            component={Select}
                                            name="idProvider"
                                            label="Select Gene ID Type"
                                            value={values.idProvider}
                                        >
                                            {idProviderMapping.map((m) => (
                                                <MenuItem key={m.key} value={m.value}>
                                                    {m.label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>

                                {/* Select species */}
                                <Grid sm={6}>
                                    <FormControl sx={{minWidth: 200}}>
                                        <Field
                                            component={Select}
                                            name="species"
                                            label="Select species"
                                            value={values.species}
                                        >
                                            <MenuItem key="human" value="human">Human</MenuItem>
                                            <MenuItem key="mouse" value="mouse">Mouse</MenuItem>
                                        </Field>
                                    </FormControl>
                                </Grid>

                                {/*<FormControl>*/}
                                {/*    {gseaGeneSets.length > 0 ?*/}
                                {/*        <Field*/}
                                {/*            component={Select}*/}
                                {/*            label="Available Gene Sets"*/}
                                {/*            name="geneSet"*/}
                                {/*            color="secondary"*/}
                                {/*            variant="outlined"*/}
                                {/*            sx={{width: '35%'}}*/}
                                {/*            disabled={isSubmitting}*/}
                                {/*        >*/}
                                {/*            {gseaGeneSets.map((g) => (*/}
                                {/*                <MenuItem key={g} value={g}>*/}
                                {/*                    {g}*/}
                                {/*                </MenuItem>*/}
                                {/*            ))}*/}
                                {/*        </Field>*/}
                                {/*        :*/}
                                {/*        <Skeleton variant="text"/>*/}
                                {/*    }*/}
                                {/*</FormControl>*/}

                                {/* Submit form */}
                                <Grid sm={6}>
                                    <LoadingButton
                                        variant="contained"
                                        component="label"
                                        size="large"
                                        loading={isSubmitting}
                                        loadingIndicator={<MiniLoading size={20}/>}
                                        onClick={submitForm}
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Grid>
    );
}
