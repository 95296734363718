import Typography from "@mui/material/Typography";

const StatsTab = (props) => {
    const {stats} = props;
    return (
        stats !== undefined ? (
                <Typography>
                    Stats go here
                </Typography>
            )
            :
            (
                <Typography>
                    Study has no stats
                </Typography>
            )
    )
}

export default StatsTab;