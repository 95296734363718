import React from "react";
import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PREFIX = 'Help';

const classes = {
    content: `${PREFIX}-content`,
    toolbar: `${PREFIX}-toolbar`
};

const Root = styled('main')((
    {
        theme
    }
) => ({
    [`&.${classes.content}`]: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },

    [`& .${classes.toolbar}`]: theme.mixins.toolbar
}));

const supportEmail = "bifrost@atyrpharma.com"


const Help = () => {

        return (
            <Root className={classes.content}>
                <div className={classes.toolbar}/>
                {' '}
                <Typography variant="h4" gutterBottom>Bifröst Help</Typography>
                <br/>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant="h5" gutterBottom>What kind of analyses can I do in Bifröst?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" gutterBottom>
                            At the moment Bifröst supports analysis of:
                            <ul>
                                <li>Single cell rnaSeq</li>
                                <li>Bulk rnaSeq</li>
                                <li>B-Cell receptor sequencing</li>
                            </ul>
                            <br/>
                            Files for analysis must be in one of the following formats:
                            <ul>
                                <li>Fastq (.fastq, .fastsanger, .fastq.gz)</li>
                                <li>BAM (.bam)</li>
                            </ul>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant="h5" gutterBottom>How do I get data from public sources (SRA, NCBI)
                            into
                            Bifröst?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" gutterBottom>
                            There are a couple of different ways to get data into Bifröst for analysis:
                            <ol>
                                <li>Get data from SRA</li>
                                <li>Get data from Google Drive (G: drive) <i>Coming Soon</i></li>
                                <li>Get data from local computer (including M: and R: drives)</li>
                            </ol>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* START Google drive / G Drive instructions */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant="h5" gutterBottom>How do I get data from the G: drive (Google Drive) into
                            Bifröst?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" gutterBottom>
                            <i>Coming Soon</i>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/* END Google drive / G Drive instructions */}

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant="h5" gutterBottom>How do I get data from the M, R drives or my local computer into
                            Bifröst?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Alert severity="warning">
                            <AlertTitle>
                                Large Files will take a long time to upload
                            </AlertTitle>
                            Due to the nature of Next Gen sequencing the files needed
                            for analysis are typically very large. This will likely result
                            in file uploads taking a very long time.
                        </Alert>
                        <Typography variant="body1" gutterBottom>
                            <ol>
                                <li>Select your desired sequencing type on the Home page</li>
                                <li>Under "Data Source" select "Upload Files"</li>
                                <li>Select the files you would like to upload</li>
                            </ol>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant="h5" gutterBottom>How do I view results?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" gutterBottom>
                            Results can be viewed and downloaded by:
                            <ol>
                                <li>Clicking on the "Studies" icon in the left side navigation bar</li>
                                <li>Navigating to the study you would like to view results for</li>
                                <li>Clicking view</li>
                            </ol>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography variant="h5" gutterBottom>What do I do if something is broken or I have
                            questions?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body1" gutterBottom>
                            If you have questions or issues please email us at{' '}
                            <Link
                                href={supportEmail}
                            >
                                bifrost@atyrpharma.com
                            </Link>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Root>
        );
    }

export default Help;