import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AddSamplesGroups = (props) => {
    const {groups, samples} = props;
    const setFieldValue = props.setfieldvalue;
    const [samplesWithGroups, setSamplesWithGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(groups[0]);

    useEffect(() => {
        setSamplesWithGroups(samples);
    }, [samples]);

    const handleCheckboxCheck = (event) => {
        setSamplesWithGroups(
            samplesWithGroups.map((sample) => {
                if (sample.sampleTitle === event.target.name) {
                    if (event.target.checked) {
                        sample["group"] = selectedGroup;
                    } else {
                        delete sample.group
                    }
                }
                return sample
            })
        );
        setFieldValue("samples", samplesWithGroups);
    }

    const handleGroupButtonClick = (event) => {
        setSelectedGroup(event.target.name);
    }

    return (
        <Box sx={{display: 'flex'}}>
            <FormControl>
                <FormGroup>
                    <Stack spacing={1}>
                        {groups.map((group) => (
                            <Button
                                variant="contained"
                                onClick={handleGroupButtonClick}
                                disabled={group === selectedGroup}
                                name={group}
                                key={group}
                            >
                                {group}
                            </Button>
                        ))}
                    </Stack>
                </FormGroup>
            </FormControl>
            <FormControl sx={{ml:3}}>
                <FormGroup>
                    {samplesWithGroups.length > 0 && samplesWithGroups.map((sample) => (
                        <FormControlLabel
                            key={sample.sampleTitle}
                            control={
                                <Checkbox
                                    checked={sample.group === selectedGroup}
                                    name={sample.sampleTitle}
                                    onChange={handleCheckboxCheck}
                                />
                            }
                            label={sample.group ? `${sample.sampleTitle} - ${sample.group}` : sample.sampleTitle}
                        />
                    ))}
                    {samplesWithGroups.length === 0 &&
                        <Typography color="error" sx={{fontWeight: "bold"}}>No samples added</Typography>
                    }
                </FormGroup>
            </FormControl>
        </Box>
    )
}

export {AddSamplesGroups}