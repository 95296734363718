import {useState} from "react";
import Box from "@mui/material/Box";
import {Field} from "formik";
import {TextField} from "formik-mui";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const GroupInput = (props) => {
    const {groups, name} = props;
    const numGroups = props.numgroups;
    const setGroups = props.setgroups;
    const setNumGroups = props.setnumgroups;
    const [groupName, setGroupName] = useState(name);
    const [toggleEdit, setToggleEdit] = useState(false);

    const handleGroupEntryConfirmation = (name) => {
        if (!groups.includes(groupName)) {
          const newGroups = groups.map((group) => group === name ? groupName : group);
          setGroups(newGroups);
          setToggleEdit((prevState) => !prevState);
        } else if (name === groupName) {
            setToggleEdit((prevState) => !prevState);
        }
    }

    const handleRemoveGroup = (name) => {
        if (numGroups > 2) {
            setGroups((prevState) => prevState.filter(group => group !== name));
            setNumGroups((prevState) => prevState - 1);
        }
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row"
        }}>
            <Field
                component={TextField}
                name={name}
                sx={{mr: 1, mb: 1}}
                defaultValue={name}
                disabled={!toggleEdit}
                onChange={(e) => {setGroupName(e.target.value)}}
            />
            {!toggleEdit &&
                <IconButton color="secondary" onClick={() => {
                    setToggleEdit((prevState) => !prevState);
                }}>
                    <EditIcon/>
                </IconButton>
            }
            {toggleEdit &&
                <Stack direction="row">
                    <IconButton color="secondary" onClick={() => {
                        handleGroupEntryConfirmation(name)
                    }}>
                        <DoneIcon/>
                    </IconButton>
                    <IconButton color="secondary" onClick={() => {
                        handleRemoveGroup(name)
                    }}>
                        <CloseIcon/>
                    </IconButton>
                </Stack>
            }
        </Box>
    )
}

export {GroupInput}