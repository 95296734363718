import {useState} from 'react';
import {useParams} from 'react-router-dom';
import Grid from "@mui/material/Unstable_Grid2";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {collection, query, Timestamp} from "firebase/firestore";
import PropTypes from "prop-types";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {db, studiesCollection} from "../../functions/firebaseFunctions";
import Skeleton from "@mui/material/Skeleton";

import SamplesTab from "./studySamplesTab";
import StatsTab from "./studyStatsTab";

const isStudyProcessing = ({jobs, studyId}) => {
    if (jobs.docs.length === 0) {
        return false;
    }
    jobs.docs.forEach((job) => {
        if (job.study_id === studyId) {
            return true;
        }
    });
    return false;
}

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `sample-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Study = (props) => {
    dayjs.extend(relativeTime)
    const {studies, jobs, setShowErrorAlert, setErrorText, setLoading, ...other} = props;
    const {studyId} = useParams();
    const study = studies.find((study) => {
        let s = null;
        if (studyId === study.study_id) {
            s = study;
        }
        return s;
    })
    const [value, setValue] = useState(0);

    const [samples, loading, error] = useCollectionData(
        query(
            collection(db, studiesCollection, study.study_id, 'samples'),
        )
    );

    if (loading) {
        return;
    }

    const processing = isStudyProcessing({jobs: jobs, studyId: studyId});

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid container>
            <Toolbar/>
            <Grid container rowSpacing={1} sx={{p: 3}}>
                <Grid item="true" xs={12}>
                    <Typography variant="h4">{study.title !== "" ? study.title : study.study_id}</Typography>
                </Grid>
                <Grid item="true">
                    <Typography variant="subtitle1" sx={{fontWeight: "bold"}} display="inline">Created At: </Typography>
                    <Typography variant="body1" display="inline">
                        {new Timestamp(study.created_at.seconds, study.created_at.nanoseconds).toDate().toDateString()}
                    </Typography>
                </Grid>
                <Grid item="true" xs={12}>
                    <Typography variant="subtitle1" sx={{fontWeight: "bold"}}
                                display="inline">Study ID: </Typography>
                    <Typography variant="body1" display="inline">{study.study_id}</Typography>
                </Grid>
                <Grid item="true" xs={12}>
                    <Typography variant="subtitle1" sx={{fontWeight: "bold"}}
                                display="inline">Description: </Typography>
                    <Typography variant="body1" display="inline">{study.description}</Typography>
                </Grid>
                <Grid item="true" xs={12}>
                    <Typography variant="subtitle1" sx={{fontWeight: "bold"}}
                                display="inline">Sequencing Type: </Typography>
                    <Typography variant="body1" display="inline">{study.seqType}</Typography>
                </Grid>
                <Grid item="true" xs={12}>
                    <Typography variant="subtitle1" sx={{fontWeight: "bold"}}
                                display="inline">Species: </Typography>
                    {loading ? <Skeleton /> : <Typography variant="body1" display="inline">{samples[0].referenceGenome.species}</Typography>}
                    {/*<Typography variant="body1" display="inline">{samples[0].referenceGenome.species}</Typography>*/}
                </Grid>
                <Grid item="true" xs={12} sx={{mt: 1.5}}>
                    <Box sx={{width: '100%'}}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={value} onChange={handleChange} aria-label="study tabs">
                                <Tab label="Samples" {...a11yProps(0)} />
                                <Tab label="Stats" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <SamplesTab
                                samples={samples}
                                processing={processing}
                                setShowErrorAlert={setShowErrorAlert}
                                setErrorText={setErrorText}
                                setDownloadLoading={setLoading}
                            />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <StatsTab stats={study.stats}/>
                        </CustomTabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Study;