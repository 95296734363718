import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';

const propTypesValue = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
}

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress
                variant="determinate" {...props}
                size={150}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="body1"
                    component="div"
                    color="text.secondary"
                >
                    {/*{`${Math.round(props.value)}%`}*/}
                    {`${props.value.toFixed(2)}%`}
                </Typography>
            </Box>
        </Box>
    )
}

CircularProgressWithLabel.propTypes = propTypesValue;


function LinearProgressWithLabel(props) {
    const progress = Math.round(props.value);
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${progress}%`}</Typography>
            </Box>
        </Box>
    );
}

// CircularProgressWithLabel.propTypes = {
//     /**
//      * The value of the progress indicator for the determinate variant.
//      * Value between 0 and 100.
//      * @default 0
//      */
//     value: PropTypes.number.isRequired,
// };

// function CircularStatic(props) {
//   const [progress, setProgress] = React.useState(10);
//   const {bytesTransferred, totalBytes} = props;
//   // setProgress((bytesTransferred / totalBytes) * 100);
//
//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
//     }, 800);
//     return () => {
//       clearInterval(timer);
//     };
//   }, []);
//
//   return <CircularProgressWithLabel value={progress} />;
// }

const BorderLinearProgress = styled(LinearProgressWithLabel)(({theme}) => ({
    height: 25,
    borderRadius: 50,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        // backgroundColor: theme.palette.primary.dark
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 50,
        backgroundColor: theme.palette.secondary.light,
        // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',

    },
}));

export {CircularProgressWithLabel, BorderLinearProgress}
