import React, {useState} from "react";
import axios from "axios";
import CountUp from 'react-countup';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from "@mui/material/Unstable_Grid2";
import * as XLSX from 'xlsx/xlsx.mjs';
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import {FormLabel, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import {AsgardAlert} from "../asgardAlert";
import {ExampleTable} from "./exampleTable";
import {MiniLoading} from "../loading";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {join} from "lodash";

const apiUrl = process.env.REACT_APP_API_GATEWAY_URL + "/convert";

export const Genotate = (props) => {
    const {user} = props;
    const {accessToken} = {...user};
    const allowedExts = ".xlsx";
    const [error, setError] = useState("");
    const [species, setSpecies] = useState("human");
    const [idType, setIdType] = useState("id");
    const [largeUpload, setLargeUpload] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [processingSuccessful, setProcessingSuccessful] = useState(false);
    const [provider, setProvider] = useState("");
    const [unknownIdIndexes, setUnknownIdIndexes] = useState([]);
    const [uploadedGenes, setUploadedGenes] = useState([]);

    const geneSymbolsIdString = "symbol";
    const geneIdsIdString = "id";

    const errorText = {
        title: "Error",
        text: "Error is processing",
        severity: "error",
        warningMessage: error
    }

    const largeUploadText = {
        title: "Large Upload",
        text: "Processing could take 2 or more minutes due to amount of genes to process.",
        severity: "warning",
        warningMessage: "Do not navigate away from this page."
    };

    const successfulProccessingText = {
        title: "Success",
        text: "Processing successful.",
        severity: "success"
    };

    const handleIdTypeChange = (event) => {
        setIdType(event.target.value);
    }

    const handleSpeciesChange = (event) => {
        setSpecies(event.target.value);
    }

    const getFilenameAndExt = (filename) => {
        const fileComponents = filename.split(".");
        const fileExt = fileComponents.pop();
        return {
            ext: fileExt,
            filename: join(fileComponents, ".")
        }
    };

    const determineProvider = (genes) => {
        let idProvider = "";
        genes.forEach((gene, idx) => {
            const prefix = gene.slice(0, 2).toUpperCase();
            let _provider = "";
            if (/^\d+$/.test(prefix)) {
                _provider = "entrez";
            } else if (prefix[0] === "Q" || prefix[0] === "P" || prefix[0] === "O") {
                _provider = "uniprot";
            } else {
                switch (prefix) {
                    case "EN":
                        _provider = "ensembl";
                        break;
                    case "UC":
                        _provider = "ucsc";
                        break;
                    case "NM":
                    case "NG":
                    case "NR":
                    case "XR":
                        _provider = "refseq";
                        break
                    case "HG":
                        _provider = "hgnc";
                        break
                    case "MG":
                        _provider = "mgi";
                        break
                    default:
                        _provider = "unknown";
                        setUnknownIdIndexes((p) => [...p, idx]);
                }
            }
            if (provider === "") {
                idProvider = _provider;
            } else if (_provider !== idProvider) {
                idProvider = "unknown";
            }
        });
        return idProvider
    };

    const fetchGeneNames = async (genes, provider) => {
        return await axios.post(apiUrl, {
                requestId: crypto.randomUUID(),
                species: species,
                fetchAllIds: false,
                // idType: idType,
                geneIds: genes,
                provider: provider
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + accessToken
                }
            }).then((response) => {
            return response
        }).catch((error) => {
            setError(error);
        });
    }

    /**
     * Reads and annotates uploaded file.
     *
     * @param {File} file - File containing gene symbols to be annotated.
     * @returns {Promise<void>}
     */
    const annotateFile = async (file) => {
        let genes = [];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
            defval: ""
        });
        for (let i = 1; i < jsonData.length; i++) {
            genes.push(jsonData[i][0]);
        }
        const _provider = idType === geneIdsIdString ? determineProvider(genes) : geneSymbolsIdString;
        setProvider(_provider);
        setUploadedGenes(genes);
        if (genes.length > 500) {
            setLargeUpload(true);
        }
        const response = await fetchGeneNames(genes, _provider);
        const annotatedGenes = response.data.genes;
        const geneSymbols = ["Gene Symbols"];
        const geneNames = ["Gene Names"];
        annotatedGenes.forEach((gene) => {
            if (idType === geneIdsIdString) {
                geneSymbols.push(gene.symbol);
            }
            geneNames.push(gene.name);
        });
        let annotatedJsonData = [];
        for (let i = 0; i < jsonData.length; i++) {
            let row = [];
            if (idType === geneIdsIdString) {
                row = [...jsonData[i].slice(0, 1), geneSymbols[i], geneNames[i], ...jsonData[i].slice(1)];
            } else {
                row = [...jsonData[i].slice(0, 1), geneNames[i], ...jsonData[i].slice(1)];
            }
            annotatedJsonData.push(row);
        }
        const {filename, ext} = {...getFilenameAndExt(file.name)};
        const annotatedFilename = `${filename}_annotated.${ext}`;
        const annotatedWorksheet = XLSX.utils.json_to_sheet(annotatedJsonData, {skipHeader: true});
        const annotatedWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(annotatedWorkbook, annotatedWorksheet, "annotated")
        XLSX.writeFile(annotatedWorkbook, annotatedFilename);
        setProcessing(false);
    }

    return (
        <Grid spacing={2} padding={2}>
            <Toolbar/>
            <Box sx={{mb: 2}}>
                <Typography variant="h4" noWrap sx={{mb: 2}}>Genotate</Typography>
                <Typography noWrap>Genotate allows users to annotate files containing gene symbols or gene ids with gene
                    names.</Typography>
            </Box>
            <Typography gutterBottom>It is expected that the column containing the gene symbols or gene ids is the first
                column
                and contains a header row.</Typography>
            <Box sx={{mt: 1, mb: 1}}>
                <ExampleTable/>
            </Box>


            <Grid container spacing={2} flexDirection="column">
                <Grid sm={2}>
                    <FormControl>
                        <FormLabel component="legend">Select Species</FormLabel>
                        <RadioGroup
                            aria-labelledby="species-selector-radio-group"
                            value={species}
                            onChange={handleSpeciesChange}
                        >
                            <FormControlLabel value="human" control={<Radio />} label="Human" disabled={processing}/>
                            <FormControlLabel value="mouse" control={<Radio />} label="Mouse" disabled={processing}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {species === "mouse" &&
                    <Grid sm={2}>
                        <Typography gutterBottom>Searching by mouse id is limited to gene symbol, Ensembl ID and Entrez ID</Typography>
                    </Grid>
                }

                <Grid sm={2}>
                    <FormControl>
                        <FormLabel component="legend">Select Gene IDs or Symbols</FormLabel>
                        <RadioGroup
                            aria-labelledby="id-type-selector-radio-group"
                            value={idType}
                            onChange={handleIdTypeChange}
                        >
                            <FormControlLabel value={geneIdsIdString} control={<Radio disabled={processing}/>} label="IDs"
                                              disabled={processing}/>
                            <FormControlLabel value={geneSymbolsIdString} control={<Radio disabled={processing}/>} label="Symbols"
                                              disabled={processing}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {largeUpload && processing &&
                    <Grid sm={2} sx={{maxWidth: "40%"}}>
                        <AsgardAlert
                            content={largeUploadText}
                        />
                    </Grid>
                }

                {!processing && processingSuccessful && error === "" &&
                    <Grid sm={2} sx={{maxWidth: "40%"}}>
                        <AsgardAlert
                            content={successfulProccessingText}
                        />
                    </Grid>
                }

                {!processing && !processingSuccessful && error !== "" &&
                    <Grid sm={2} sx={{maxWidth: "40%"}}>
                        <AsgardAlert
                            content={errorText}
                        />
                    </Grid>
                }

                <Card sx={{
                    maxWidth: "25%",
                    mb: 2
                }}
                      variant="outlined">
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Upload Stats</Typography>
                        <Typography variant="body1" gutterBottom>Number of genes uploaded: <CountUp
                            end={uploadedGenes.length}/></Typography>
                        <Typography variant="body1">
                            ID Provider: {idType === "symbol" ? "Symbols" : provider.toUpperCase()}
                        </Typography>
                    </CardContent>
                </Card>

                <Grid sm={2}>
                    <LoadingButton
                        variant="contained"
                        component="label"
                        size="large"
                        loading={processing}
                        loadingIndicator={<MiniLoading size={20}/>}
                    >
                        Select File to Annotate
                        <input
                            type="file"
                            accept={allowedExts}
                            hidden
                            onChange={(e) => {
                                setProcessing(true);
                                annotateFile(e.target.files[0])
                                    .then((result) => {
                                        setProcessingSuccessful(true);
                                        setProcessing(false);
                                    })
                                    .catch((err) => {
                                        setError(err);
                                        setProcessingSuccessful(false);
                                        setProcessing(false);
                                    });
                            }
                            }
                        />
                    </LoadingButton>
                </Grid>
            </Grid>
        </Grid>
    )
}