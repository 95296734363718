import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {MiniLoading} from "../loading";
import {useDownloadURL} from "react-firebase-hooks/storage";
import {ref, storage} from "../../functions/firebaseFunctions";
import axios from "axios";
import fileDownload from "js-file-download";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Box from "@mui/material/Box";

const DownloadHandler = (props) => {
    const {sample, setDownloadLoading, setErrorText, setShowErrorAlert} = props;
    const gcs = sample.gcsLocation;
    const [value, loading, error] = useDownloadURL(ref(storage, gcs));
    const st = sample.sampleTitle.concat(`.${gcs.split(".").pop()}`);

    const dl = () => {
        axios({
            url: value,
            method: 'GET',
            responseType: 'blob'
        })
            .then((res) => {
                setDownloadLoading(false);
                fileDownload(res.data, st);
            }).catch((error) => {
            console.log(error);
            setDownloadLoading(false);
            setShowErrorAlert(true);
            setErrorText("An error occurred in downloading sample output");
        })
    }

    return (
        <>
            {loading && <MiniLoading/>}
            {!loading && error &&
                <Typography
                    sx={{color: (theme) => theme.palette.error.main}}
                >
                    Error fetching download link
                </Typography>
            }
            {!loading && value &&
                <IconButton
                    onClick={
                        (() => {
                            setDownloadLoading(true);
                            dl();
                        })}
                >
                    <DownloadIcon/>
                </IconButton>
            }
        </>
    )
}

const SamplesTab = (props) => {
    const {processing, samples, setShowErrorAlert, setErrorText, setDownloadLoading} = props;
    let columns = ['Sample Title'];

    if (samples !== undefined) {
        if ('metadata' in samples[0]) {
            for (const key in samples[0].metadata) {
                columns.push(key);
            }
        }
    }

    columns.push('Download')

    return (
        <Box>
            {samples !== undefined ? (
                <TableContainer>
                    <Table aria-label="study-sample-table">
                        <TableHead>
                            <TableRow>
                                {columns.map((col) => (
                                    <TableCell>{col}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {samples.map((sample) => (
                                <TableRow
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        {sample.sampleTitle}
                                    </TableCell>
                                    {'metadata' in sample && columns.slice(1, -1).map((value) => {
                                        return <TableCell>{sample.metadata[value]}</TableCell>
                                    })
                                    }
                                    <TableCell>
                                        {processing && <MiniLoading/>}
                                        {!processing && ('gcsLocation' in sample) &&
                                            <DownloadHandler
                                                sample={sample}
                                                setDownloadLoading={setDownloadLoading}
                                                setShowErrorAlert={setShowErrorAlert}
                                                setErrorText={setErrorText}
                                            />
                                        }
                                        {!processing && !('gcsLocation' in sample) &&
                                            <Typography>No Download Link Available</Typography>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>
                    Study has no samples
                </Typography>
            )

            }
        </Box>
    )
}
export default SamplesTab;