import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {ArrowRightAlt} from "@mui/icons-material";


export const ExampleTable = () => {
    return (
        <Box>
            <Typography gutterBottom>For files containing gene ids, gene symbol and name will be added in the two
                columns to the
                right of the gene id column</Typography>
            <Grid container flexDirection="row" spacing={10} marginBottom={1}>
                <Grid sm={2}>
                    <TableContainer component={Paper} sx={{minWidth: 150}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Gene ID</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        ENSG00000118257
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid sm={1}>
                        <ArrowRightAlt fontSize="large"/>
                    </Grid>
                </Grid>
                <Grid sm={4}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Gene ID</TableCell>
                                    <TableCell>Gene Symbol</TableCell>
                                    <TableCell>Gene Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        ENSG00000118257
                                    </TableCell>
                                    <TableCell>
                                        NRP2
                                    </TableCell>
                                    <TableCell>
                                        neuropilin 2
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Typography gutterBottom sx={{mt: 1}}>For files containing gene symbols, gene name will be added in the
                column to the
                right of the gene id column</Typography>
            <Grid container flexDirection="row" spacing={10}>
                <Grid sm={2}>
                    <TableContainer component={Paper} sx={{minWidth: 150}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Gene Symbol</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        NRP2
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid sm={1}>
                        <ArrowRightAlt fontSize="large"/>
                    </Grid>
                </Grid>
                <Grid sm={4}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Gene Symbol</TableCell>
                                    <TableCell>Gene Name</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        NRP2
                                    </TableCell>
                                    <TableCell>
                                        neuropilin 2
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
}