import React, {useState, forwardRef} from 'react';
import {styled} from '@mui/material/styles';
import AddchartIcon from '@mui/icons-material/Addchart';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import Button from '@mui/material/Button';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import {Field, Form, Formik} from 'formik';
import {RadioGroup, Select, TextField} from 'formik-mui';
import Divider from "@mui/material/Divider";
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import {Accordion, AccordionDetails} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";

// import {GoogleDriveFileLoader} from "./fileInteractions/googleDriveFileLoader";
import {FileUpload} from "../fileInteractions/fileUpload";
import {generateJobId, submitJobHandler} from "../../functions/firebaseFunctions";
import {StudyRunTable} from "../studyRunTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";

import {ValidationSchema} from "./formValidation";
import {DgeSampleGroupAssignment} from "./dge/dgeSampleGroupAssignment";
import {BowtieAdvancedOptions} from "./advancedOptions/bowtieAdvancedOptions";
import Box from "@mui/material/Box";
import SampleHeader from "./sampleHeader";
import CircularProgress from "@mui/material/CircularProgress";
import {FileGrid} from "../fileInteractions/fileGrid";
import {useUploadFile} from "react-firebase-hooks/storage";
import InsightsIcon from "@mui/icons-material/Insights";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";

const PREFIX = 'newStudyForm';

const classes = {
    root: `${PREFIX}-root`,
    appBar: `${PREFIX}-appBar`,
    button: `${PREFIX}-button`,
    actionsContainer: `${PREFIX}-actionsContainer`,
    resetContainer: `${PREFIX}-resetContainer`,
    form: `${PREFIX}-form`,
    formItem: `${PREFIX}-formItem`,
    title: `${PREFIX}-title`,
    errorMessage: `${PREFIX}-errorMessage`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.appBar}`]: {
        position: 'relative',
    },

    [`& .${classes.button}`]: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    [`& .${classes.actionsContainer}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.resetContainer}`]: {
        padding: theme.spacing(3),
    },

    [`& .${classes.form}`]: {
        margin: theme.spacing(4, 4, 4),
    },

    [`& .${classes.formItem}`]: {
        margin: theme.spacing(3, 3, 3)
    },

    [`& .${classes.title}`]: {
        marginLeft: theme.spacing(2),
        flex: 1
    },

    [`& .${classes.errorMessage}`]: {
        color: theme.palette.error,
        fontWeight: 'bold'
    }
}));

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function NewStudyForm(props) {
    const [open, setOpen] = useState(false);
    const [jobId, setJobId] = useState("");
    const [addedStudies, setAddedStudies] = useState([])
    const {setFormSubmitted, setFormOpen, setSubmitSuccessful} = {...props};
    const {studies, user} = props;

    // File grid state vars
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [lookupDone, setLookupDone] = useState(true);
    const [lookupError, setLookupError] = useState(null);

    // File upload state vars
    const [selectedFilesToUpload, setSelectedFilesToUpload] = useState([]);
    const [filesRemainingToUpload, setFilesRemainingToUpload] = useState(0);

    const [uploadFile, uploading, snapshot, storageError] = useUploadFile();

    const newStudy = {
        id: null,
        name: '',
        description: '',
        title: '',
        nameSelect: 'Create a new study'
    };

    const [study, setStudy] = useState(newStudy);
    const isNewStudy = study !== newStudy;

    const filterErrors = (errors) => {
        if (errors.samples) {
            const sampleError = errors.samples;
            const dgeGroupError = `Cannot process groups that span multiple studies`;
            if ((dgeGroupError === sampleError) || sampleError.includes('Missing group')) {
                errors.groups = sampleError;
                delete errors.samples;
            }
        }
        return errors;
    }

    const handleNewStudyFormOpen = () => {
        setJobId(generateJobId());
        setOpen(true);
        setFormOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormOpen(false);
    };

    const handleStudySelectChange = (setFieldValue, event) => {
        const targetValue = event.target.value;
        const study = targetValue === newStudy.nameSelect
            ? newStudy
            : studies.find(({name}) => name === targetValue);
        setFieldValue('study', study);
        setStudy(study);
    };

    const submitHandler = (values) => {
        values['jobId'] = jobId;
        values['isNewStudy'] = isNewStudy;
        submitJobHandler(values).then((result) => {
            setSubmitSuccessful(true);
        }).catch((e) => {
            console.log(e);
            setSubmitSuccessful(false);
        });
        setFormSubmitted(true);
        handleClose();
    };

    const upload = async () => {
        if (selectedFilesToUpload) {
            let filesProcessed = 0;
            for (const sf of selectedFilesToUpload) {
                const result = await uploadFile(sf.ref, sf, {
                    contentType: 'application/octet-stream'
                });
                setFilesRemainingToUpload((filesRemaining) => (filesRemaining - 1));
                filesProcessed++;
                console.log(`Result: ${JSON.stringify(result)}`);
            }
            return filesProcessed;
        }
    }

    return (
        <Root>
            <Stack spacing={2} direction="row">
                <Button
                    component="label"
                    role={undefined}
                    id="start-analysis-button"
                    variant="contained"
                    color={"primary"}
                    onClick={handleNewStudyFormOpen}
                    startIcon={<AddchartIcon />}
                >
                    Start an Analysis
                </Button>
                <Button
                    component="label"
                    role={undefined}
                    id="view-studies-button"
                    variant="contained"
                    color={"primary"}
                    href="/studies"
                    startIcon={<AnalyticsOutlinedIcon />}
                >
                    View Analyzed Studies
                </Button>
                <Button
                    component="label"
                    role={undefined}
                    id="genesight-button"
                    variant="contained"
                    color={"primary"}
                    href="/genesight"
                    startIcon={<InsightsIcon />}
                >
                    Perform Enrichment Analysis
                </Button>
                <Button
                    component="label"
                    role={undefined}
                    id="genotate-button"
                    variant="contained"
                    color={"primary"}
                    href="/genotate"
                    startIcon={<AutoFixHighIcon />}
                >
                    Annotate Gene Ids
                </Button>
            </Stack>

            <Dialog
                fullScreen
                open={open}
                // onClose={handleClose}
                TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Create a new or add to a study
                        </Typography>
                    </Toolbar>
                </AppBar>

                <Paper
                    sx={{mt: 5, ml: 2, mr: 2, mb: 2}}
                    elevation={5}
                >
                    <Box sx={{pl: 3, pt: 4}}>
                        <Formik
                            // enableReinitialize={true}
                            initialValues={{
                                study: newStudy,
                                dataSource: '',
                                currentDataSource: '',
                                submitted_by: user.email,
                                isMultiStudy: false,
                                samples: [],
                                aligner: 'star',
                                alignerOptions: {
                                    star: {
                                        outputGenomeBam: true
                                    },
                                    bowtie2: {
                                        alignmentsPerRead: 200,
                                        sensitivityLevel: "sensitive"
                                    },
                                },
                                quantifier: {
                                    pairEnded: true
                                },
                                jobType: 'count',
                                preprocessing: {
                                    trim: true
                                },
                                referenceGenome: {
                                    annotations: 'default',
                                    publisher: 'ensembl',
                                    species: 'human'
                                },
                                seqType: 'bulk',
                                jobOptions: {
                                    runBatchCorrection: false,
                                    dge: {
                                        comparisons: []
                                    }
                                },
                            }}
                            validationSchema={ValidationSchema}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                    console.log(values);
                                    submitHandler(values);
                                }, 500);
                            }}
                        >
                            {({
                                  submitForm,
                                  isSubmitting,
                                  values,
                                  setFieldValue,
                                  handleChange,
                                  errors,
                                  validateField
                              }) => (
                                <Form>
                                    {/* Begin Data Source*/}
                                    <Typography variant="h4" sx={{mb: 1}}>Data Source</Typography>
                                    <FormLabel component="legend" sx={{mb: 1}}>
                                        Enter NCBI BioProject ID and/or upload files. Uploaded sample files can be
                                        analyzed with publicly available data.
                                    </FormLabel>
                                    <SampleHeader
                                        errors={errors}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />
                                    <StudyRunTable
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        setStudy={setStudy}
                                        setColumns={setColumns}
                                        setRows={setRows}
                                        setLookupDone={setLookupDone}
                                        setLookupError={setLookupError}
                                        values={values}
                                        uploading={uploading}
                                    />

                                    <FileUpload
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        jobId={jobId}
                                        errors={errors}
                                        setColumns={setColumns}
                                        setRows={setRows}
                                        filesRemaining={filesRemainingToUpload}
                                        setFilesRemaining={setFilesRemainingToUpload}
                                        selectedFiles={selectedFilesToUpload}
                                        setSelectedFiles={setSelectedFilesToUpload}
                                        uploading={uploading}
                                        snapshot={snapshot}
                                        storageError={storageError}
                                        values={values}
                                    />
                                    {/* End Data Source */}

                                    {/* Begin Sample Grid */}
                                    {!lookupDone && (
                                        <>
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <CircularProgress
                                                    size={50}
                                                    color="secondary"
                                                    sx={{
                                                        position: 'relative',
                                                        zIndex: '1000',
                                                    }}/>
                                            </Box>
                                        </>
                                    )}
                                    {lookupDone && rows.length > 0 && (
                                        <>
                                            <FileGrid
                                                columns={columns}
                                                rows={rows}
                                                setRows={setRows}
                                                setFieldValue={setFieldValue}
                                                validateField={validateField}
                                                samples={values.samples}
                                                studyName={values.study.name}
                                                lookupError={lookupError}
                                                setAddedStudies={setAddedStudies}
                                                addedStudies={addedStudies}
                                                newStudy={newStudy}
                                                setStudy={setStudy}
                                                currentDataSource={values.currentDataSource}
                                                upload={upload}
                                                uploading={uploading}
                                            />
                                        </>
                                    )}
                                    {/* End Sample Grid */}

                                    {/* Begin Study Details */}
                                    <Typography variant="h4">Study Details</Typography>
                                    <br/>
                                    <FormControl sx={{minWidth: 200}}>
                                        <Field
                                            component={Select}
                                            name="study.nameSelect"
                                            label="Select a Study"
                                            onChange={(e) => {
                                                handleStudySelectChange(setFieldValue, e);
                                                handleChange(e);
                                            }}
                                            value={isNewStudy ? newStudy.nameSelect : study.name}
                                        >
                                            <MenuItem
                                                key={newStudy.nameSelect}
                                                value={newStudy.nameSelect}
                                            >
                                                {newStudy.nameSelect}
                                            </MenuItem>
                                            <Divider/>
                                            {studies.map((study) => (
                                                <MenuItem
                                                    key={study.name}
                                                    value={study.name}
                                                >
                                                    {study.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>


                                    {isNewStudy &&
                                        <>
                                            <br/>
                                            <br/>
                                            <Field
                                                component={TextField}
                                                label="Study Name"
                                                name="study.name"
                                                color="secondary"
                                                variant="outlined"
                                                multiline
                                                value={study.name}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setFieldValue('study.name', value);
                                                    setStudy((prevState) => {
                                                        prevState['name'] = value;
                                                        return prevState;
                                                    })
                                                }}
                                            >
                                                {study.name}
                                            </Field>
                                        </>
                                    }

                                    <br/>
                                    <br/>

                                    <Field
                                        component={TextField}
                                        label="Study Description"
                                        name="study.description"
                                        color="secondary"
                                        variant="outlined"
                                        multiline
                                        value={study.description}
                                        sx={{width: '50%'}}
                                        rows={5}
                                        disabled={!isNewStudy}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            setFieldValue('study.description', value);
                                            setStudy((prevState) => {
                                                prevState['description'] = value;
                                                return prevState;
                                            })
                                        }}
                                    >
                                        {study.description}
                                    </Field>
                                    {/* End Study Details */}

                                    <br/>
                                    <br/>

                                    <FormLabel component="legend">Sequencing Type</FormLabel>
                                    <Field component={RadioGroup} name="seqType">
                                        <FormControlLabel
                                            value="bulk"
                                            control={<Radio disabled={isSubmitting}/>}
                                            label="Bulk RNA Sequencing"
                                            disabled={isSubmitting}
                                        />
                                        <FormControlLabel
                                            value="single-cell"
                                            control={<Radio disabled={isSubmitting}/>}
                                            label="Single Cell (ie., 10x) Sequencing"
                                            disabled={isSubmitting}
                                        />
                                        <FormControlLabel
                                            value="bcr"
                                            control={<Radio disabled={isSubmitting}/>}
                                            label="B-Cell Receptor Sequencing"
                                            disabled={isSubmitting}
                                        />
                                    </Field>
                                    <br/>

                                    <FormLabel component="legend">Job Type</FormLabel>
                                    <FormControl>
                                        <Field
                                            component={RadioGroup}
                                            name="jobType"
                                            variant="outlined"
                                            label="Job Type"
                                        >
                                            {values.seqType === "bulk" && (
                                                <>
                                                    <FormControlLabel
                                                        value="count"
                                                        control={<Radio disabled={isSubmitting}/>}
                                                        label="Count"
                                                        disabled={isSubmitting}
                                                    />
                                                    <FormControlLabel
                                                        value="dge"
                                                        control={<Radio disabled={isSubmitting}/>}
                                                        label="Differential Gene Expression"
                                                        disabled={isSubmitting}
                                                    />
                                                </>
                                            )}
                                            {values.seqType === "single-cell" && (
                                                <>
                                                    <FormControlLabel
                                                        value="count"
                                                        control={<Radio disabled={isSubmitting}/>}
                                                        label="Count"
                                                        disabled={isSubmitting}
                                                    />
                                                    <FormControlLabel
                                                        value="aggr"
                                                        control={<Radio disabled={isSubmitting}/>}
                                                        label="Aggregation"
                                                        disabled={isSubmitting}
                                                    />
                                                </>
                                            )}
                                            {values.seqType === "bcr" && (
                                                <>
                                                    <FormControlLabel
                                                        value="clone-trees"
                                                        control={<Radio disabled={isSubmitting}/>}
                                                        label="Build Clonal Trees"
                                                        disabled={isSubmitting}
                                                    />
                                                </>
                                            )}

                                        </Field>
                                    </FormControl>
                                    {errors.jobType ?
                                        (<Typography color="error"
                                                     fontWeight="bold">{errors.jobType}</Typography>)
                                        :
                                        null
                                    }

                                    <br/>

                                    {/* Spike sequence for BCR analysis */}
                                    {values.seqType === "bcr" && (
                                        <>
                                            <Field
                                                component={TextField}
                                                label="Clonal Sequence"
                                                name="clonalSequence"
                                                helperText="Enter the clonal sequence"
                                                color="secondary"
                                                variant="outlined"
                                                multiline
                                                rows={5}
                                                sx={{mb: 2, mt: 1, width: '50%'}}
                                            />
                                        </>
                                    )}

                                    {/* Sample to group assignment for DGE */}
                                    {values.seqType === "bulk" && values.jobType === "dge" &&
                                        <>
                                            <DgeSampleGroupAssignment
                                                setfieldvalue={setFieldValue}
                                                samples={values.samples}
                                                errors={filterErrors(errors)}
                                            />
                                            <FormLabel component="legend">Run Batch Correction</FormLabel>
                                            <FormControl sx={{mb: 2}}>
                                                <Field
                                                    component={RadioGroup}
                                                    name="jobOptions.runBatchCorrection"
                                                    variant="outlined"
                                                    label="Run Batch Correction"
                                                >
                                                    <FormControlLabel
                                                        value={false}
                                                        control={<Radio disabled={isSubmitting}/>}
                                                        label="No"
                                                        disabled={isSubmitting}
                                                    />
                                                    <FormControlLabel
                                                        value={true}
                                                        control={<Radio disabled={isSubmitting}/>}
                                                        label="Yes"
                                                        disabled={isSubmitting}
                                                    />
                                                </Field>
                                            </FormControl>
                                        </>
                                    }

                                    <br/>

                                    {/* Analysis Params */}
                                    <Typography variant="h5">Analysis Parameters</Typography>
                                    <br/>

                                    {/* Pre-processing Options */}
                                    <Typography variant="h6">Pre-processing Options</Typography>
                                    <br/>
                                    {/* Trimming Options */}
                                    <FormLabel component="legend">Reads are Trimmed</FormLabel>
                                    <Field component={RadioGroup} name="preprocessing.trim">
                                        <FormControlLabel
                                            value={false}
                                            control={<Radio disabled={isSubmitting}/>}
                                            label="Yes"
                                            disabled={isSubmitting}
                                        />
                                        <FormControlLabel
                                            value={true}
                                            control={<Radio disabled={isSubmitting}/>}
                                            label="No"
                                            disabled={isSubmitting}
                                        />
                                    </Field>
                                    <br/>

                                    {/* Hide reference genome options if doing bcrSeq */}
                                    {values.seqType !== "bcr" && (
                                        <>
                                            {/* Reference Options */}
                                            <Typography variant="h6">Reference Genome Options</Typography>
                                            <br/>

                                            {/* Reference Publisher */}
                                            <FormLabel component="legend">Genome Publisher</FormLabel>
                                            <Field component={RadioGroup} name="referenceGenome.publisher">
                                                <FormControlLabel
                                                    value="ensembl"
                                                    control={<Radio disabled={isSubmitting}/>}
                                                    label="Ensembl"
                                                    disabled={isSubmitting}
                                                />
                                                <FormControlLabel
                                                    value="ucsc"
                                                    control={<Radio disabled={isSubmitting}/>}
                                                    label="UCSC RefSeq"
                                                    disabled={isSubmitting}
                                                />
                                            </Field>
                                            <br/>
                                            {/* Reference Species */}
                                            <FormLabel component="legend">Genome Species</FormLabel>
                                            <Field
                                                component={RadioGroup}
                                                name="referenceGenome.species"
                                            >
                                                <FormControlLabel
                                                    value="human"
                                                    control={<Radio disabled={isSubmitting}/>}
                                                    label="Human"
                                                    disabled={isSubmitting}
                                                />
                                                <FormControlLabel
                                                    value="mouse"
                                                    control={<Radio disabled={isSubmitting}/>}
                                                    label="Mouse"
                                                    disabled={isSubmitting}
                                                />
                                            </Field>
                                            {errors.referenceGenome ?
                                                (<Typography color="error"
                                                             fontWeight="bold">{errors.referenceGenome.species}</Typography>)
                                                :
                                                null
                                            }
                                            <br/>
                                            {/* Reference Annotations */}
                                            {/*<FormLabel component="legend">Genome Annotations</FormLabel>*/}
                                            {/*<Field component={RadioGroup} name="referenceGenome.annotations">*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        value="default"*/}
                                            {/*        control={<Radio disabled={isSubmitting}/>}*/}
                                            {/*        label="No custom annotations"*/}
                                            {/*        disabled={isSubmitting}*/}
                                            {/*    />*/}
                                            {/*    <FormControlLabel*/}
                                            {/*        value="nrp2"*/}
                                            {/*        control={<Radio disabled={isSubmitting}/>}*/}
                                            {/*        label="NRP2"*/}
                                            {/*        disabled={isSubmitting}*/}
                                            {/*    />*/}
                                            {/*</Field>*/}
                                        </>
                                    )}

                                    {/* Start advanced options */}
                                    {values.seqType !== "single-cell" &&
                                        <Accordion sx={{width: '50%'}}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon/>}
                                            >
                                                <Typography variant="h6" gutterBottom>Advanced Options</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {/* Show aligner options if not scRNA or bcrSeq*/}
                                                {values.seqType === "bulk" && (
                                                    <>
                                                        <Typography variant="h6">Aligner Options</Typography>
                                                        <br/>
                                                        {/* Select aligner */}
                                                        <FormLabel component="legend">Select Aligner</FormLabel>
                                                        <Field component={RadioGroup} name="aligner">
                                                            <FormControlLabel
                                                                value="star"
                                                                control={<Radio disabled={isSubmitting}/>}
                                                                label="STAR"
                                                                disabled={isSubmitting}
                                                            />
                                                            <FormControlLabel
                                                                value="bowtie2"
                                                                control={<Radio disabled={isSubmitting}/>}
                                                                label="Bowtie 2"
                                                                disabled={isSubmitting}
                                                            />
                                                        </Field>
                                                        <br/>
                                                        {/* STAR options */}
                                                        {values.aligner === 'star' && (
                                                            <div>
                                                                <FormLabel component="legend">Output Genome BAM
                                                                    File</FormLabel>
                                                                <Field component={RadioGroup}
                                                                       name="alignerOptions.outputGenomeBam">
                                                                    <FormControlLabel
                                                                        value={true}
                                                                        control={<Radio disabled={isSubmitting}/>}
                                                                        label="Yes"
                                                                        disabled={isSubmitting}
                                                                    />
                                                                    <FormControlLabel
                                                                        value={false}
                                                                        control={<Radio disabled={isSubmitting}/>}
                                                                        label="No"
                                                                        disabled={isSubmitting}
                                                                    />
                                                                </Field>
                                                                <br/>
                                                            </div>
                                                        )}
                                                        {/* Bowtie2 options */}
                                                        {values.aligner === 'bowtie2' &&
                                                            <BowtieAdvancedOptions isSubmitting={isSubmitting}/>}

                                                        <Typography variant="h6">Quantifier Options</Typography>
                                                        {/*<Typography variant="overline">RSEM is the only available quantifier at this time</Typography>*/}
                                                        <br/>
                                                        {/* Pair ended */}
                                                        <FormLabel component="legend">Pair Ended</FormLabel>
                                                        <Field component={RadioGroup}
                                                               name="quantifier.pairEnded">
                                                            <FormControlLabel
                                                                value={true}
                                                                control={<Radio disabled={isSubmitting}/>}
                                                                label="Yes"
                                                                disabled={isSubmitting}
                                                            />
                                                            <FormControlLabel
                                                                value={false}
                                                                control={<Radio disabled={isSubmitting}/>}
                                                                label="No"
                                                                disabled={isSubmitting}
                                                            />
                                                        </Field>
                                                        <br/>
                                                    </>
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {/* End advanced options */}

                                    {/* Submit form */}
                                    {isSubmitting && <LinearProgress sx={{mb: 2}}/>}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        sx={{mt: 2, mb: 2}}
                                    >
                                        Submit
                                    </Button>
                                    <br/>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Dialog>
        </Root>
    );
}

export default NewStudyForm;