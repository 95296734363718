import {useState} from "react"
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const AsgardErrorDialog = (props) => {
    const {errorMsg, showError, setShowErrorAlert} = props;
    const [open, setOpen] = useState(Boolean(showError));

    const handleClose = () => {
        setOpen(false);
        setShowErrorAlert(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="asgard-error-title"
                aria-describedby="asgard-error-description"
            >
                {/*<DialogTitle id="asgard-error-title">*/}
                {/*    {"An Error Occurred"}*/}
                {/*</DialogTitle>*/}
                <DialogContent>
                    <DialogContentText id="asgard-error-description">
                        {errorMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="secondary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AsgardErrorDialog;