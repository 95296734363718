import Box from "@mui/material/Box";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography";

const SampleChip = (props) => {
    const sampleTitle = props.sample.sampleTitle;
    const handleRemoveSample = props.handleRemoveSample;

    return (
        <Chip
            label={sampleTitle}
            variant="outlined"
            color="secondary"
            onDelete={() => handleRemoveSample(sampleTitle)}
        />
    )
}

const SelectedSamples = (props) => {

    return (
        <Box sx={{
            mb: 2,
        }}>
            <Typography variant="h6" mb={1}>Selected Samples</Typography>
            <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                {props.samples.map((sample) => (
                    <SampleChip
                        key={sample.sampleTitle + sample.id}
                        sample={sample}
                        handleRemoveSample={props.handleRemoveSample}
                    />
                ))}
            </Stack>
        </Box>
    )
}

export {SelectedSamples}