import React from "react";

import Alert from "@mui/material/Alert";
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export default function AlertDialog(props) {
    const {severity, title, text} = {...props};
    const [open, setOpen] = React.useState(Boolean(props.open));

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <Alert severity={severity}>
                    <AlertTitle>{title}</AlertTitle>
                    {text}
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button variant="filled" onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}