import Typography from "@mui/material/Typography";
import {SelectedSamples} from "./selectedSamples";


const SampleHeader = (props) => {
    const {errors, values, setFieldValue} = props;
    const handleRemoveSample = (sampleTitle) => {
        const samples = values.samples.filter(sample => sample.sampleTitle !== sampleTitle);
        setFieldValue('samples', samples);
    }

    return (
        <>

            {values.samples.length > 0 &&
                <>
                    <Typography variant="h4" mb={1}>
                        Samples
                    </Typography>
                    <SelectedSamples
                        samples={values.samples}
                        handleRemoveSample={handleRemoveSample}
                    />
                </>
            }
            {errors.samples && <Typography color="error" mb={2} fontWeight="bold">{errors.samples}</Typography>}
        </>
    )
}

export default SampleHeader;