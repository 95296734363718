import axios from "axios";
import axiosRetry from "axios-retry";
import {create} from "xmlbuilder2";

const NCBI_API_KEY = "31afafb507be6ed5e59e3285832880dce709";
const baseUrl = new URL("https://eutils.ncbi.nlm.nih.gov/entrez/eutils/");
const searchUrl = baseUrl + "esearch.fcgi";
const fetchUrl = baseUrl + "efetch.fcgi";
const db = "sra";
const MAX_RETURNED_SEARCH_RESULTS = 100000;
const ALLOWED_LIBRARY_STRATEGIES = ["RNA-SEQ"];

axiosRetry(axios, {retries: 3, retryDelay: axiosRetry.exponentialDelay});

async function getSraStudies(searchTerm) {
    const idListObj = await axios.get(searchUrl, {
        params: {
            db: db,
            term: searchTerm,
            api_key: NCBI_API_KEY,
            retmax: MAX_RETURNED_SEARCH_RESULTS
        }
    }).then((result) => {
        const doc = create(result.data).doc().end({format: 'object'});
        return doc["eSearchResult"]["IdList"]["Id"];
    }).catch((error) => {
        console.log(error);
    });
    return await getStudyMetadata(idListObj);
}

async function getStudyMetadata(idList) {
    const ids = idList.join();
    return await axios.get(fetchUrl, {
        params: {
            db: db,
            id: ids,
            api_key: NCBI_API_KEY
        }
    }).then((result) => {
        const doc = create(result.data)
            .doc()
            .end({format: 'object'});
        const experiments = doc["EXPERIMENT_PACKAGE_SET"]["EXPERIMENT_PACKAGE"];
        const study = experiments[0].STUDY;
        const studyTitle = study.DESCRIPTOR.STUDY_TITLE;
        const studyDescription = study.DESCRIPTOR.STUDY_ABSTRACT;
        const samples = experiments.map((experiment) => {
            const sample = experiment["SAMPLE"];
            let sampleAttributes = {};
            if ("SAMPLE_ATTRIBUTES" in sample) {
                sampleAttributes = getSampleAttributes(sample["SAMPLE_ATTRIBUTES"]["SAMPLE_ATTRIBUTE"]);
            } else {
                sampleAttributes["attribs"] = {};
            }
            const runs = getStudyRuns(experiment["RUN_SET"]);
            const libraryDescriptor = experiment["EXPERIMENT"]["DESIGN"]["LIBRARY_DESCRIPTOR"];
            const {seqType, library, libraryStrategy} = tryDetermineSeqTypeAndLibrary(libraryDescriptor);
            return {
                sampleTitle: sample["TITLE"],
                runs: runs,
                metadata: sampleAttributes["attribs"],
                isPairEnded: Object.hasOwn(libraryDescriptor["LIBRARY_LAYOUT"], "PAIRED"),
                seqType: seqType,
                library: library,
                isSelectable: ALLOWED_LIBRARY_STRATEGIES.includes(libraryStrategy),
                libraryStrategy: libraryStrategy,
                species: getSpecies(sample)
            };
        });
        return {
            studyTitle: studyTitle,
            studyDescription: studyDescription,
            samples: samples
        }
    });
}

function getSampleAttributes(sampleAttributesArray) {
    let tags = [];
    let attribs = {};
    sampleAttributesArray.forEach((attrib) => {
        let tag = attrib["TAG"];
        tags.push(tag);
        attribs[tag] = attrib["VALUE"];
    });
    return {tags: tags, attribs: attribs};
}

function getSpecies(sample) {
    const scientificName = sample["SAMPLE_NAME"]["SCIENTIFIC_NAME"].toUpperCase();
    let species = "";
    if (scientificName.includes("SAPIEN")) {
        species = "human";
    } else if (scientificName.includes("MUS")) {
        species = "mouse";
    } else {
        species = "NA";
    }
    return species;
}

/**
 *
 * @param runSet
 * @returns {{runFiles: *, accession: *}[]}
 */
function getStudyRuns(runSet) {
    const publicS3Bucket = "s3://sra-pub-run-odp/sra";
    const run = runSet["RUN"];
    const runs = Array.isArray(run) ? run : [run];
    return runs.map((run) => {
        const accession = run["@accession"]
        let runFiles = run["SRAFiles"]["SRAFile"].filter((sraFile) => {
            return sraFile["@supertype"] === "Original";
        }).map((sraFile) => {
            let run;
            if (Array.isArray(sraFile["Alternatives"])) {
                for (const alt of sraFile["Alternatives"]) {
                    if (alt["@org"] === "GCP") {
                        run = alt["@url"];
                    }
                }
            }
            return run;
        });

        // If no GCP links found then use the public s3 bucket address.
        if (checkArrayAllUndefined(runFiles)) {
            runFiles = [`${publicS3Bucket}/${accession}/${accession}`]
        }
        return {accession: accession, runFiles: runFiles}
    });
}

function tryDetermineSeqTypeAndLibrary(libraryDescription) {
    const librarySource = libraryDescription["LIBRARY_SOURCE"].toUpperCase();
    const libraryConstructionProtocol = libraryDescription["LIBRARY_CONSTRUCTION_PROTOCOL"].toUpperCase();
    const libraryStrategy = libraryDescription["LIBRARY_STRATEGY"].toUpperCase();
    const scre = /SINGLE[_\s-]CELL/g;
    const tenxre = /(10X)(CHROMIUM)?/g;
    const isSingleCell = () => {
        let found = false;
        if (librarySource.search(scre) === -1) {
            if (libraryConstructionProtocol.search(scre) !== -1) {
                found = true;
            }
        } else {
            found = true;
        }
        return found;
    }

    const is10x = () => {
        return (libraryConstructionProtocol.search(tenxre) !== -1);
    }

    const isBulk = () => {
        return (libraryStrategy === "RNA-SEQ" && librarySource === "TRANSCRIPTOMIC");
    }

    const seqType = () => {
        if (isSingleCell()) {
            return "single-cell";
        } else if (isBulk()) {
            return "bulk";
        } else {
            return undefined;
        }
    }

    let st = seqType();
    let stObj = {"seqType": st, "library": null, "libraryStrategy": libraryStrategy}

    if (st === "single-cell") {
        stObj["library"] = is10x() ? "10x" : null;
    }

    return stObj;
}

/**
 *  Checks if all values in an array are undefined or null.
 * @param {Array} array - the array being checked.
 * @returns {boolean}
 */
function checkArrayAllUndefined(array) {
    let numberUndefinedElements = 0;
    array.forEach((a) => {
        if (a == null) {
            numberUndefinedElements++;
        }
    })
    return numberUndefinedElements === array.length;
}

export {getSraStudies}