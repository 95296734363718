import React from "react";

import Box from "@mui/material/Box";
import {getStorage} from 'firebase/storage';
import {app, ref} from '../functions/firebaseFunctions';
import {useDownloadURL} from 'react-firebase-hooks/storage';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const installerBucket = "gs://bifrost-installers";
const installers = {
    "loupe": {
        "filename": "Loupe-Browser-6.1.0.exe",
        "path": "/installers/Loupe-Browser-6.1.0.exe"
    }
}

const storage = getStorage(app);

export const Downloads = (props) => {
    const {user, setLoading, ...other} = props;
    const [value, loading, error] = useDownloadURL(ref(storage, installers.loupe.path));

    const handleDownloadClick = (filename) => {
        setLoading(true);

        axios({
            url: value,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        });
    }

    return (
        <Box sx={{padding: 2, flexGrow: 1}}>
            <Box sx={theme => theme.mixins.toolbar}/>
            <Box>
                <Typography variant="h4" gutterBottom>
                    scRNA Downloads
                </Typography>
                <Typography variant="body1">
                    These downloads can assist with the analysis of 10x scRNA
                    analyses processed by Bifrost:
                    <List>
                        <ListItem sx={{display: 'list-item'}}>
                            Loupe Browser - Application to graph and visualize the output of a 10x scRNA analysis
                        </ListItem>
                    </List>
                </Typography>
                {loading && <Button>
                    <Skeleton/>
                </Button>}
                {!loading && <Button
                    variant="contained"
                    onClick={(() => {
                        handleDownloadClick(installers.loupe.filename)
                    })}
                >
                    Loupe Browser - Windows
                </Button>}
            </Box>
        </Box>
    )
}