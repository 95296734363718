import {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from '@mui/material/Select';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ComparisonInput = (props) => {
    const {comparisons, groups, i} = props;
    const setComparisons = props.setcomparisons;
    const setFieldValue = props.setfieldvalue;
    const [localComparison, setLocalComparison] = useState([]);
    const comparisonNumber = `Comparison ${i + 1}`

    const handleComparisonChange = (event) => {
        const {
            target: {value},
        } = event;

        if (value.length < 3) {
            setLocalComparison(typeof value === 'string' ? value.split(',') : value);
        }

        if (value.length === 2) {
            setComparisons((prevState) => {
                prevState[i] = value;
                return prevState;
            });
            setFieldValue("jobOptions.dge.comparisons", comparisons);
        }
    }

    const handleComparisonDelete = (event) => {
        setComparisons((prevState) => prevState.filter((comp, idx) => idx !== i));
    }

    return (
        <FormControl sx={{mb: 1.5}}>
            <Box sx={{
                display: "flex",
                flexDirection: "row"
            }}>
                <InputLabel id="comparison-select-label">{comparisonNumber}</InputLabel>
                <Select
                    labelId="comparison-select-label"
                    id="comparison-select"
                    multiple
                    value={localComparison}
                    onChange={handleComparisonChange}
                    input={<OutlinedInput id="select-multiple-chip" label={comparisonNumber}/>}
                    renderValue={(selected) => (
                        <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value} label={value}/>
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    sx={{minWidth: "254px"}}
                >
                    {groups.map((group) => (
                        <MenuItem
                            key={group}
                            value={group}
                        >
                            {group}
                        </MenuItem>
                    ))}
                </Select>
                <IconButton color="secondary" onClick={handleComparisonDelete}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </FormControl>
    )
}


const PairwiseComparisons = (props) => {
    const {groups} = props;
    const [comparisons, setComparisons] = useState([[]]);

    const handleAddComparison = (event) => {
        setComparisons((prevState) => [...prevState, []]);
    }

    return (
        <FormControl>
            {comparisons.length === 0 &&
                <Typography color="error" sx={{fontWeight: "bold"}}>No comparisons</Typography>}
            {comparisons.length > 0 && comparisons.map((comparison, i) => (
                <ComparisonInput
                    key={`comparison-${i}`}
                    groups={groups}
                    i={i}
                    comparisons={comparisons}
                    setcomparisons={setComparisons}
                    setfieldvalue={props.setfieldvalue}
                />
            ))}
            <Button
                color="secondary"
                variant="outlined"
                sx={{border: 2, borderRadius: "16px", mb: 2, maxWidth: "207px"}}
                onClick={handleAddComparison}
                endIcon={<AddIcon/>}
            >
                Add Comparison
            </Button>
        </FormControl>
    )
}

export {PairwiseComparisons}