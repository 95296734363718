import React, {useRef, useState} from "react";
import {ref, storage} from '../../functions/firebaseFunctions';
import filesize from 'filesize';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import LinearProgress from '@mui/material/LinearProgress';

import AlertDialog from "../alertDialog";
import {CircularProgressWithLabel} from "../progress";

function FileUpload(props) {
    const {
        jobId,
        setRows,
        setColumns,
        dataSource,
        setFieldValue,
        selectedFiles,
        setSelectedFiles,
        filesRemaining,
        setFilesRemaining,
        uploading,
        snapshot,
        storageError,
        values
    } = props;
    const bytesRemaining = useRef(0);
    const bytesTransferred = useRef(0);
    const totalFileSize = useRef(0);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [snapshotBytesTransferred, setSnapshotBytesTransferred] = useState(0);

    const columns = [
        {
            field: 'sampleTitle', headerName: "Sample Title", editable: true, width: 300
        },
        // {
        //     field: 'species',
        //     headerName: 'Species',
        //     editable: true,
        //     type: 'singleSelect',
        //     valueOptions: ['human', 'mouse']
        // },
        {
            field: 'run', headerName: 'Run Number', editable: true, width: 150
        },
        {
            field: 'group', headerName: "Group", editable: true, width: 150,
        },
        {
            field: 'fileName', headerName: "File Name", width: 350
        },
        {
            field: 'fileSize', headerName: "File Size"
        }
    ]

    // For upload progress graphic
    React.useEffect(() => {
        if (snapshot) {
            const timer = setInterval(() => {
                const bt = snapshot.bytesTransferred - bytesTransferred.current;
                if (bt > 0) {
                    const br = bytesRemaining.current - bt;
                    setSnapshotBytesTransferred(bt);
                    bytesTransferred.current = bytesTransferred.current + bt;
                    bytesRemaining.current = br;
                    const progressMade = (snapshotBytesTransferred / totalFileSize.current) * 100;
                    setUploadProgress((prevUploadProgress) =>
                        (prevUploadProgress >= 100
                            ? 0
                            : prevUploadProgress + progressMade));
                    console.log(`Bytes transferred: ${snapshotBytesTransferred} - ${bytesTransferred.current} - ${bytesRemaining.current} - ${progressMade} - Progress:${uploadProgress} - Files remaining: ${filesRemaining}`)
                }
            }, 200);
        }
        // else {
        //     // console.log("Waiting for snapshot...");
        //     // setSnapshotState("none");
        //     return;
        // }
        return () => {
        }
        // }
    }, [snapshot])

    const filesToRows = (files) => {
        if (values.dataSource === "sra" || values.dataSource === "mixed") {
            setFieldValue("dataSource", "mixed");
        } else {
            setFieldValue("dataSource", "fileUpload");
        }
        setFieldValue("currentDataSource", "fileUpload");
        let fs = [];
        let tfs = 0;
        for (let i = 0; i < files.length; i++) {
            let f = files[i];
            const fileName = f.name;
            tfs += f.size;
            f['id'] = i;
            f['ref'] = ref(storage, `jobs/${jobId}/${fileName}`);
            f['fileName'] = fileName;
            f['fileSize'] = filesize(f.size);
            f['sampleTitle'] = `Sample ${(Math.floor(i / 2)) + 1}`;
            f['run'] = 1;
            f['isSelectable'] = true;
            fs.push(f);
        }
        setSelectedFiles(fs);
        totalFileSize.current = tfs;
        bytesRemaining.current = tfs;
        setFilesRemaining(fs.length);
        setRows(fs);
        setColumns(columns);
    }

    return (
        <Box sx={{mb: 2}}>
            {/* Error uploading file*/}
            {storageError && <AlertDialog open={true}/>}

            {/* While file is uploading */}
            {uploading && filesRemaining > 0 &&
                <Box>
                    {totalFileSize.current > 125000000 &&
                        <Alert severity="warning" sx={{width: '50%', mb: 1}}>
                            <AlertTitle>Large File Upload</AlertTitle>
                            Caution: This upload could take a long time depending
                            on your connection. You might want to grab some coffee ☕️
                        </Alert>
                    }
                    <Typography variant="body1" sx={{mb: 2}}>
                        Files remaining: {filesRemaining}
                    </Typography>
                    {/*<CircularProgressWithLabel value={uploadProgress}/>*/}
                    <LinearProgress sx={{mt: 2, mr: 2}}/>
                </Box>
            }

            {/* Show alert when files have been uploaded successfully */}
            {!storageError && !uploading && filesRemaining === 0 && selectedFiles.length > 0 && dataSource === "fileUpload" &&
                <Box>
                    <Alert severity="success" sx={{width: '50%', mb: 1}}>
                        <AlertTitle>Files Uploaded Successfully</AlertTitle>
                        {selectedFiles.length} {selectedFiles.length > 1 ? "files have" : "file has"} been uploaded
                    </Alert>
                </Box>
            }

            {/* Show table of selected files to upload */}
            {selectedFiles.length > 0 && !uploading &&
                <>
                    {/*{errors && <Typography color="error" mb={2} fontWeight="bold">{errors.samples}</Typography>}*/}
                    <Typography variant="body1">
                        Select the files you'd like to upload
                    </Typography>
                    <Typography variant="body2" sx={{fontWeight: "bold", mb:1}}>
                        Edit Sample Title and Group information before selecting and uploading samples
                    </Typography>
                </>
            }

            {/* Show button to select files to upload before uploading */}
            {!uploading &&
                <Button
                    variant="contained"
                    component="label"
                    size={"large"}
                    sx={{mr: 1}}
                >
                    Select Files to Upload
                    <input
                        type="file"
                        multiple
                        hidden
                        onChange={(e) => {
                            filesToRows(e.target.files);
                        }
                        }
                    />
                </Button>
            }
        </Box>
    )
}

    export {FileUpload}