import {BrowserRouter as Router, Route, Routes, Switch} from 'react-router-dom';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import Login from './pages/login';
import Home from './pages/home';
import Studies from './components/study/studies';

LicenseInfo.setLicenseKey('44963800ec9cb7dd26652202da2d0405Tz04MjU3MixFPTE3MzczMTE3NjMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            // main: '#385767',
            main: '#3D5C6C',
            light: '#5f7885',
            dark: '#273c48',
            contrastText: '#fff'
        },
        secondary: {
            main: '#f50057',
            light: '#f73378',
            dark: '#ab003c'
        },
        background: {
            paper: '#171C22',
            default: '#0E1217'
        }
    },
    typography: {
        fontFamily: "Open Sans",
        overline: {
            fontSize: '.95rem',
            fontWeight: 'bold'
        }
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                enableColorOnDark: true
            }
        }
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Routes>
                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/" element={<Home el="home"/>}/>
                <Route exact path="/help" element={<Home el="help"/>}/>
                <Route path="/study/:studyId" element={<Home el="study"/>}/>
                <Route path="/studies" element={<Home el="studies"/>}/>
                <Route path="/jobs" element={<Home el="job"/>}/>
                <Route path="/genotate" element={<Home el="genotate"/>}/>
                <Route path="/genesight" element={<Home el="genesight"/>}/>
                <Route path="/downloads" element={<Home el="downloads"/>}/>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
