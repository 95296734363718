import React from 'react';

import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useCollection} from "react-firebase-hooks/firestore";
import {db, jobsCollection, restartJob} from "../../functions/firebaseFunctions";
import {Loading} from "../loading";
import {collection, collectionGroup, query, where, Timestamp} from "firebase/firestore";
import NewStudyForm from "../newStudyForm/newStudyForm";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";

import {BorderLinearProgress} from "../progress";
import IconButton from "@mui/material/IconButton";

const PREFIX = 'Job';

const classes = {
    content: `${PREFIX}-content`,
    toolbar: `${PREFIX}-toolbar`
};

const StyledBox = styled(Box)((
    {
        theme
    }
) => ({
    [`& .${classes.content}`]: {
        // flexGrow: 1,
        padding: theme.spacing(3),
        width: '100%',
    },

    [`& .${classes.toolbar}`]: theme.mixins.toolbar
}));

const getJobProgress = (jobSteps) => {
    let stepsCompleted = 0;
    for (const s of jobSteps) {
        const step = s.data();
        if (step.failed) {
            return -1;
        } else if (step.completed) {
            stepsCompleted++;
        }
    }

    return (stepsCompleted / jobSteps.length) * 100;
}

const getDateAndTime = (createdAt) => {
    const ts = new Timestamp(createdAt.seconds, createdAt.nanoseconds);
    const date = ts.toDate();
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

const FailedJobRetryButton = (props) => {
    const {user, jobId} = props;
    return (
        <StyledBox sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
        }}
        >
            {user.isAdmin && <IconButton onClick={() => {
                restartJob(jobId)
            }}>
                <ReplayRoundedIcon fontSize="large"/>
            </IconButton>}
            <ErrorOutlineRoundedIcon fontSize="large" color="error"/>
        </StyledBox>
    );
}

const Job = (props) => {
    const {user, studies} = props;

    const userIsAdmin = user.isAdmin;

    let jobQuery = query(
        collection(db, jobsCollection)
    )

    if (!userIsAdmin) {
        jobQuery = query(jobQuery,
            where("submitted_by", "==", user.email))
    }

    const [value, loading, error] = useCollection(
        jobQuery
    )

    const [subValue, subLoading, subError] = useCollection((
        query(
            collectionGroup(db, 'job_steps'),
        )
    ))

    return (
        <Box sx={{
            padding: 2,
            width: 'auto',
            flexGrow: 1
        }}>
            <Toolbar/>

            <Stack direction="row" spacing={2} sx={{mb: 2}}>
                <Typography variant="h4">All Jobs</Typography>
                <NewStudyForm studies={studies} user={user}/>
            </Stack>
            {(loading || subLoading) && <Loading/>}
            {!loading && !subLoading && value && subValue &&
                <TableContainer>
                    <Table aria-label="job-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Job Name
                                </TableCell>
                                {userIsAdmin && <TableCell>
                                    Job ID
                                </TableCell>}
                                <TableCell>
                                    Started At
                                </TableCell>
                                <TableCell>
                                    Study Name
                                </TableCell>
                                <TableCell>
                                    Data Source
                                </TableCell>
                                <TableCell>
                                    Sequencing Type
                                </TableCell>
                                <TableCell>
                                    Job Type
                                </TableCell>
                                {userIsAdmin && <TableCell>
                                    Submitted By
                                </TableCell>}
                                <TableCell colSpan={6} align="center">
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value.docs.map((doc) => (
                                <TableRow
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>
                                        {doc.data().name}
                                    </TableCell>
                                    {userIsAdmin && <TableCell>
                                        {doc.data().job_id}
                                    </TableCell>}
                                    <TableCell>
                                        {getDateAndTime(doc.data().created_at)}
                                    </TableCell>
                                    <TableCell>
                                        {doc.data().study_id}
                                    </TableCell>
                                    <TableCell>
                                        {doc.data().data_source}
                                    </TableCell>
                                    <TableCell>
                                        {doc.data().seq_type}
                                    </TableCell>
                                    <TableCell>
                                        {doc.data().job_type}
                                    </TableCell>
                                    {userIsAdmin && <TableCell>
                                        {doc.data().submitted_by}
                                    </TableCell>}
                                    <TableCell width={'20%'} align="center">
                                        {
                                            (() => {
                                                const jobSteps = subValue.docs.filter((value) => {
                                                    return value.data().job_id === doc.id;
                                                });
                                                const jobProgress = getJobProgress(jobSteps);
                                                if (jobProgress === -1) {
                                                    return <FailedJobRetryButton jobId={doc.data().job_id}
                                                                                 user={user}/>
                                                } else if (isNaN(jobProgress)) {
                                                    return <CircularProgress color="secondary"/>
                                                } else if (jobProgress === 100) {
                                                    return <CheckCircleIcon fontSize="large" color="success"/>
                                                } else {
                                                    return <BorderLinearProgress variant="determinate"
                                                                                 value={jobProgress}/>
                                                }
                                            })()
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </Box>
    )
}

export default Job;
