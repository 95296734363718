import React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import StudyCard from './studyCard';
import NewStudyForm from "../newStudyForm/newStudyForm";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";

dayjs.extend(relativeTime)

// TODO: Add download for all study related files
const StudyGrid = (props) => {
    const {studies} = props;

    return (
        <Grid container spacing={2}>
            {studies.map((study) => (
                <Grid xs={6} key={study.study_id}>
                    <StudyCard
                        study={study}
                    />
                </Grid>
                ))}
        </Grid>
    )
};


function Studies(props) {
    const {studies, user} = props;

    return (
        <Box sx={{flexGrow: 1, padding: 2}}>
            <Toolbar/>

            <Stack direction="row" spacing={2} sx={{mb: 1}}>
                <Typography variant="h4">All Studies</Typography>
                <NewStudyForm studies={studies} user={user}/>
            </Stack>

            <StudyGrid
                studies={studies}
            />
        </Box>
    );
}

export default Studies;
