import FormLabel from "@mui/material/FormLabel";
import {Field} from "formik";
import {RadioGroup, Select} from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";

const BowtieAdvancedOptions = (isSubmitting) => {
    return (
        <div>
            <FormLabel component="legend">Alignments Per
                Read</FormLabel>
            <Field component={RadioGroup}
                   name="alignerOptions.alignmentsPerRead">
                <FormControlLabel
                    value={true}
                    control={<Radio disabled={isSubmitting}/>}
                    label="Yes"
                    disabled={isSubmitting}
                />
                <FormControlLabel
                    value={false}
                    control={<Radio disabled={isSubmitting}/>}
                    label="No"
                    disabled={isSubmitting}
                />
            </Field>
            <br/>
            <FormLabel component="legend">Bowtie2 Sensitivity
                Level</FormLabel>
            <Field component={Select}
                   name="alignerOptions.sensitivityLevel"
            >
                <MenuItem value="sensitive">Sensitive</MenuItem>
                <MenuItem value="very-sensitive">Very
                    Sensitive</MenuItem>
                <MenuItem value="fast">Fast</MenuItem>
                <MenuItem value="very-fast">Very Fast</MenuItem>
            </Field>
            <br/>
        </div>
    )
}

export {BowtieAdvancedOptions}