import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";

const Loading = (props) => {
    const {show} = props;
    let text = props.text !== undefined ? props.text : "Fetching all the things...";
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh"
        }}>
            <Backdrop
                sx={{
                    color: '#fff',
                    backgroundColor: (theme) => theme.palette.background.default,
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={show}
            >
                <Typography variant="overline" sx={{mr: 2}}>
                    {text}
                </Typography>
                <CircularProgress
                    size={50}
                    color="secondary"
                    sx={{
                        position: 'relative',
                        zIndex: '1000',
                    }}/>
            </Backdrop>
        </Box>
    );
}

const MiniLoading = (props) => {
    return (
        <CircularProgress
            color="secondary"
            role="progressbar"
            size={props.size ? props.size : 40}
            sx={{
                position: 'relative',
                zIndex: '1000',
            }}
        />
    )
}

export  {Loading, MiniLoading};