import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {GroupInput} from "./groupInput";
import {AddSamplesGroups} from "./addSamplesGroups";
import {PairwiseComparisons} from "./pairwiseComparisons";
import Typography from "@mui/material/Typography";

const DgeSampleGroupAssignment = (props) => {
    const setFieldValue = props.setfieldvalue;
    const samples = props.samples;
    const [groups, setGroups] = useState(["group1", "group2"]);
    const [numGroups, setNumGroups] = useState(2);
    const errors = props.errors;
    const groupError = errors.groups;
    const comparisonsError = errors.jobOptions?.dge?.comparisons;


    const handleAddInputElement = () => {
        setGroups((prevState) => [...prevState, `group${numGroups + 1}`]);
        setNumGroups((prevState) => prevState + 1);
    }

    return (
        <FormControl>
            <FormLabel sx={{mb: 1}}>Enter Groups (minimum 2)</FormLabel>
            {groups.map((group, i) => (
                <GroupInput
                    key={`${group}-${i}`}
                    name={group}
                    groups={groups}
                    numgroups={numGroups}
                    setgroups={setGroups}
                    setnumgroups={setNumGroups}
                />
            ))}
            <Button
                color="secondary"
                variant="outlined"
                sx={{border: 2, borderRadius: "16px", mb: 1, maxWidth: "207px"}}
                onClick={handleAddInputElement}
                endIcon={<AddIcon/>}
            >
                Add Group
            </Button>

            <FormLabel sx={{mb: 1, mt: 1}}>Add Samples to Groups</FormLabel>
            <AddSamplesGroups samples={samples} groups={groups} setfieldvalue={setFieldValue}/>
            {groupError && <Typography color="error" mb={2} fontWeight="bold">{groupError}</Typography>}

            <FormLabel sx={{mt: 2}}>Select Groups to Compare</FormLabel>
            <FormLabel sx={{mb: 1, fontSize: "small"}}>Values will be computed relative to the first group</FormLabel>
            <PairwiseComparisons groups={groups} setfieldvalue={setFieldValue}/>
            {comparisonsError && <Typography color="error" mb={2} fontWeight="bold">{comparisonsError}</Typography>}

        </FormControl>
    )
}

export {DgeSampleGroupAssignment}