import {
    browserLocalPersistence,
    getAuth,
    GoogleAuthProvider,
    setPersistence,
    signInWithPopup,
    signOut
} from "firebase/auth";
import {
    collection,
    collectionGroup,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    query,
    setDoc,
    Timestamp,
    updateDoc,
    where,
    writeBatch,
} from "firebase/firestore";
import {getBlob, getStorage, ref} from "firebase/storage";
import {getFunctions, httpsCallable} from "firebase/functions";
import {initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import generateName from "./nameGenerator";

const firebaseConfig = {
    apiKey: "AIzaSyAAs-SpolYaNXt7IKjcIjTAswjIQhjQT3s",
    authDomain: "bifrost-308015.firebaseapp.com",
    projectId: "bifrost-308015",
    storageBucket: "bifrost-308015.appspot.com",
    messagingSenderId: "1047774250086",
    appId: "1:1047774250086:web:5edc1fcc99fd91b4a319a7",
    measurementId: "G-508REDYHF7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('email');
googleProvider.addScope('profile');
googleProvider.addScope("https://www.googleapis.com/auth/drive");
googleProvider.addScope("https://www.googleapis.com/auth/drive.install");
googleProvider.addScope("https://www.googleapis.com/auth/drive.file");
googleProvider.addScope("https://www.googleapis.com/auth/drive.appdata");

const studiesCollection = process.env.REACT_APP_STUDIES_COLLECTION;
const jobsCollection = process.env.REACT_APP_JOBS_COLLECTION;
const resultsPath = process.env.REACT_APP_RESULTS_PATH;
const resultFolder = resultsPath.split("/").pop();

function persistentLogin() {
    setPersistence(auth, browserLocalPersistence).then(() => {
        return signInWithPopup(auth, googleProvider)
    });
}

const generateJobId = () => {
    return doc(collection(db, jobsCollection)).id;
}

const generateStudyId = () => {
    return doc(collection(db, studiesCollection)).id;
}

const updateUser = async (user) => {
    let firstLogin = false;
    let userInfo = {"firstLogin": firstLogin, "isAdmin": false};
    try {
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        const docRef = doc(db, "users", user.uid);
        if (docs.docs.length === 0) {
            firstLogin = true;
            await setDoc(docRef, {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
                lastLoginTime: Timestamp.now(),
                firstLogin: false
            });
        } else {
            await setDoc(docRef, {
                lastLoginTime: Timestamp.now(),
            }, {merge: true})
            const docSnap = (await getDoc(docRef)).data();
            userInfo.isAdmin = "isAdmin" in docSnap && docSnap.isAdmin === true;
        }
        return userInfo;
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
    return userInfo;
}


const logout = async () => {
    await signOut(auth).then(r => {
        // console.log("user logged out")
    });
};

const getStudyMeta = httpsCallable(functions, 'getStudyMeta');

const renameSamples = (samples) => {
    return samples.map((s) => {
        const st = s.sampleTitle;
        s.sampleTitle = st.replace(/\s/g, '_').replace(/\./g, '_').replace(/[[\]()+,]+/g, '').replace(/S/g, 's')
        return s;
    })
}

const addDownloadRefToSample = (samples, seqType) => {

}

const restartJob = async (jobId) => {
    const q = query(collectionGroup(db, "job_steps"), where("job_id", "==", jobId));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
        console.log(`Error in fetching job: ${jobId} not found`);
        throw Error
    }
    const batch = writeBatch(db);
    const jobSteps = docs.docs.map((doc) => {
        return {"ref": doc.ref, "data": doc.data()}
    });
    jobSteps.forEach((jobStep) => {
        let jobStepData = jobStep.data;
        if (stepNeedsRestart(jobStepData)) {
            jobStepData.failed = false;
            jobStepData.started = false;
            jobStepData.completed = false;
        }
        if (jobStepData.step_id === "aggr" || jobStepData.step_id === "dge") {
            jobStepData.dependencies.forEach((dependency) => {
                if (stepNeedsRestart(dependency)) {
                    dependency.failed = false;
                    dependency.started = false;
                    dependency.completed = false;
                }
            });
        }
        batch.set(jobStep.ref, jobStepData);
    });
    await batch.commit()
}

const stepNeedsRestart = (jobStepData) => {
    return !!(jobStepData.failed && jobStepData.started && jobStepData.completed);
}

// TODO: move all sample transformations into this function
const transformSamples = (samples, referenceGenome) => {
    let transformedSamples = renameSamples(samples);
    let transformedConsolidatedSamples = [];
    let fileUploadedSampleObj = {};
    transformedSamples = transformedSamples.map((s) => {
        s['referenceGenome'] = referenceGenome;
        return s;
    })

    for (const s of transformedSamples) {
        if ("fileName" in s) {
            const sampleTitle = s.sampleTitle;
            const run = s.run - 1;
            if (!(sampleTitle in fileUploadedSampleObj)) {
                fileUploadedSampleObj[sampleTitle] = {
                    sampleTitle: sampleTitle,
                    run: s.run,
                    id: s.id,
                    referenceGenome: s.referenceGenome,
                    'runs': [{
                        'runFiles': []
                    }]
                }
                if ('group' in s) {
                    fileUploadedSampleObj[sampleTitle]['group'] = s.group;
                }
            }
            fileUploadedSampleObj[sampleTitle]['runs'][run]['runFiles'].push(`gs://${s.ref.bucket}/${s.ref.fullPath}`);
        } else {
            transformedConsolidatedSamples.push(s);
        }
    }

    return transformedConsolidatedSamples.concat(Object.values(fileUploadedSampleObj));
}

async function submitJobHandler(data) {
    const jobId = data.jobId;
    const jobType = data.jobType;
    const seqType = data.seqType;
    const dataSource = data.dataSource;
    const referenceGenome = data.referenceGenome;
    const newStudy = data.isNewStudy;
    let studyId = data.study.name;
    const submitted_by = data.submitted_by;
    let aggr_sample_title = null;

    const samples = transformSamples(data.samples, referenceGenome);

    let samplesWithRefAndDownload = samples.map((s) => {
        s['processed'] = false;
        s['study_id'] = studyId;
        s['source_study_id'] = s.sourceStudyId ? s.sourceStudyId : studyId;
        s['seqType'] = seqType;
        s['study_collection'] = studiesCollection;
        s['submitted_by'] = submitted_by;
        s['metadata'] = s.hasOwnProperty('metadata') ? s.metadata : {};
        const sampleTitle = s.sampleTitle;
        let samplePath = `${resultFolder}/${studyId}/${sampleTitle}/output/`
        // samplePath += `${sampleTitle}/output/`
        if (seqType === "single-cell") {
            samplePath += `${sampleTitle}/outs/cloupe.cloupe`
        } else if (seqType === 'bulk') {
            samplePath += `${sampleTitle}.isoforms.results`
        } else { // seqType === 'bcr'
            samplePath += `${sampleTitle}.germ-pass.tsv`
        }
        const r = ref(storage, samplePath);
        s['gcsLocation'] = `${r.fullPath}`

        return s;
    })

    if (jobType === 'aggr' || jobType === 'dge') {
        aggr_sample_title = `${studyId}-${jobType}-${Timestamp.now().toMillis()}`;
        let gcsLocation = `${resultFolder}/${studyId}/${aggr_sample_title}/output/`;
        let runs = [
            {
                "runFiles": []
            }
        ];
        const gcsStudyBase = `${resultsPath}/${studyId}`;
        if (jobType === 'aggr') {
            gcsLocation += `${aggr_sample_title}/outs/count/cloupe.cloupe`;
            samples.forEach((s) => {
                runs[0].runFiles.push(`${gcsStudyBase}/${s.sampleTitle}/output/${s.sampleTitle}/outs/molecule_info.h5`)
            });
        } else if (jobType === 'dge') {
            gcsLocation += `${aggr_sample_title}_output.zip`
            samples.forEach((s) => {
                runs[0].runFiles.push(`${gcsStudyBase}/${s.sampleTitle}/output/${s.sampleTitle}.isoforms.results`)
            });
        }
        samplesWithRefAndDownload.push({
            "id": samples.length + 1,
            "sampleTitle": aggr_sample_title,
            "processed": false,
            "study_id": studyId,
            "seqType": seqType,
            "study_collection": studiesCollection,
            "submitted_by": submitted_by,
            "aggregated_samples": samples,
            "referenceGenome": referenceGenome,
            "gcsLocation": ref(storage, gcsLocation).fullPath,
            "runs": runs,
            "metadata": {} // TODO: update this to include relevant metadata
        })
    }

    if (newStudy) {
        const docRef = await setDoc(doc(db, studiesCollection, studyId), {
            "created_at": Timestamp.now(),
            "description": data.study.description,
            "name": data.study.name,
            "title": data.study.title === null || data.study.title === "" ? data.study.name : data.study.title,
            "submitted_by": submitted_by,
            "seqType": data.seqType,
            "study_id": studyId
        })
            .catch();
    } else {
        const docRef = doc(db, studiesCollection, studyId);
        await updateDoc(docRef, {
            "updated_at": Timestamp.now(),
        })
    }

    if ("dge" in data.jobOptions) {
        if ("comparisons" in data.jobOptions.dge) {
            let comparisons = {}
            data.jobOptions.dge.comparisons.forEach((comp, i) => {
                comparisons[i] = comp;
            });
            data.jobOptions.dge.comparisons = comparisons;
        }
    }

    let jobData = {
        "name": generateName(),
        "study_id": studyId,
        "job_id": jobId,
        "seq_type": data.seqType,
        "created_at": Timestamp.now(),
        "submitted_by": submitted_by,
        "data_source": dataSource,
        "job_type": jobType,
        "job_options": data.jobOptions,
        "is_multi_study": data.isMultiStudy,
        "results_path": resultsPath,
        "aggr_sample_title": aggr_sample_title
    };

    if (seqType !== "bcr") {
        jobData["reference"] = referenceGenome;
    }

    if (seqType === "bcr") {
        jobData["clonal_sequence"] = data.clonalSequence;
    }

    if (seqType === "bulk") {
        const aligner = data.aligner;
        jobData["aligner"] = aligner;
        if (aligner === 'star') {
            delete data.alignerOptions.bowtie2
        } else {
            delete data.alignerOptions.star
        }
        jobData["aligner_options"] = data.alignerOptions;
        jobData["quantifier"] = data.quantifier;
    }

    if (seqType !== "bulk") {
        delete data.alignerOptions;
        delete data.aligner;
        delete data.quantifier;
    }

    console.log(`Job data: ${jobData}`);
    await setDoc(doc(db, jobsCollection, jobId), jobData).catch((e) => {
        console.log(`Failed to create job: ${e}`)
    });

    // Add samples to be processed to study
    const batch = writeBatch(db);
    samplesWithRefAndDownload.forEach((s) => {
        s['created_at'] = Timestamp.now();
        const sid = `${s.sampleTitle}-${s.referenceGenome.publisher}-${s.referenceGenome.annotations}`
        const ref = doc(db, `${studiesCollection}/${studyId}/samples/${sid}`);
        batch.set(ref, s)
    })
    await batch.commit();

    return jobData;
}

export {
    app,
    auth,
    analytics,
    db,
    functions,
    getBlob,
    jobsCollection,
    studiesCollection,
    getStudyMeta,
    ref,
    storage,
    generateJobId,
    generateStudyId,
    logout,
    persistentLogin,
    submitJobHandler,
    restartJob,
    updateUser
}