import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import {CardActionArea} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Timestamp} from "firebase/firestore";
import {Link} from "react-router-dom";

const StudyCard = (props) => {
    const {study} = props;
    const createdAt = new Timestamp(study.created_at.seconds, study.created_at.nanoseconds).toDate().toDateString();

    return (
        <Card sx={{mt: 1}} variant="outlined">
            <CardActionArea
                component={Link}
                to={`/study/${study.name}`}
                reloadDocument
            >
                <CardHeader
                    title={study.title !== "" ? study.title : study.study_id}
                    subheader={createdAt}
                />
                <CardContent>
                    <Typography variant="h6">
                        Study ID
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {study.name}
                    </Typography>
                    <br/>
                    <Typography variant="h6">
                        Study Description
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {study.description}
                    </Typography>
                    <br/>
                    {study.seqType && <>
                        <Typography variant="h6">
                            Sequencing Type
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {study.seqType}
                        </Typography>
                    </>
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default StudyCard;