/**
 * Generates names ala docker's name generator.
 */

const adjs = ["adamant",
    "adroit",
    "amatory",
    "animistic",
    "antic",
    "arcadian",
    "baleful",
    "bellicose",
    "bilious",
    "boorish",
    "calamitous",
    "caustic",
    "cerulean",
    "comely",
    "concomitant",
    "contumacious",
    "corpulent",
    "crapulous",
    "defamatory",
    "didactic",
    "dilatory",
    "dowdy",
    "efficacious",
    "effulgent",
    "egregious",
    "endemic",
    "equanimous",
    "execrable",
    "fastidious",
    "feckless",
    "fecund",
    "friable",
    "fulsome",
    "garrulous",
    "guileless",
    "gustatory",
    "heuristic",
    "histrionic",
    "hubristic",
    "incendiary",
    "insidious",
    "insolent",
    "intransigent",
    "inveterate",
    "invidious",
    "irksome",
    "jejune",
    "jocular",
    "judicious",
    "lachrymose",
    "limpid",
    "loquacious",
    "luminous",
    "mannered",
    "mendacious",
    "meretricious",
    "minatory",
    "mordant",
    "munificent",
    "nefarious",
    "noxious",
    "obtuse",
    "parsimonious",
    "pendulous",
    "pernicious",
    "pervasive",
    "petulant",
    "platitudinous",
    "precipitate",
    "propitious",
    "puckish",
    "querulous",
    "quiescent",
    "rebarbative",
    "recalcitrant",
    "redolent",
    "rhadamanthine",
    "risible",
    "ruminative",
    "sagacious",
    "salubrious",
    "sartorial",
    "sclerotic",
    "serpentine",
    "spasmodic",
    "strident",
    "taciturn",
    "tenacious",
    "tremulous",
    "trenchant",
    "turbulent",
    "turgid",
    "ubiquitous",
    "uxorious",
    "verdant",
    "voluble",
    "voracious",
    "wheedling",
    "withering",
    "zealous"];

const names = ["isaac-newton",
"leonhard-euler",
"gottfried-von-leibniz",
"carl-friedrich-gauss",
"michael-faraday",
"alhazen-ibn-al-haytham",
"galileo-galilei",
"nikola-tesla",
"marie-sklodowska-curie",
"albert-einstein",
"euclid-of-alexandria",
"louis-pasteur",
"johannes-kepler",
"liu-hui",
"max-planck",
"augustin-louis-cauchy",
"james-clerk-maxwell",
"avicenna-of-persia-",
"hermann-von-helmholtz",
"dmitri-mendeleev",
"robert-koch",
"ernest-rutherford",
"nicolaus-copernicus",
"georg-bernhard-riemann",
"zhang-heng",
"blaise-pascal",
"muhammad-ibn-musa-al-khwarizmi",
"jules-henri-poincaré",
"abu-rayhan-al-biruni",
"isambard-kingdom-brunel",
"claudius-galen-of-pergamon",
"joseph-louis-lagrange",
"qin-jiushao",
"paul-ehrlich",
"archimedes-of-syracuse",
"nasir-al-din-al-tusi",
"robert-boyle",
"pierre-simon-laplace",
"srinivasa-ramanujan",
"wernher-von-braun",
"henri-becquerel",
"david-hilbert",
"abu-al-qasim-ibn-al-abbas-al-zahrawi",
"zhu-shijie",
"gregor-mendel",
"amelie-emmy-noether",
"antoine-lavoisier",
"brahmagupta",
"edward-jenner",
"amedeo-avogadro",
"zu-chongzhi",
"james-watt",
"rené-descartes",
"john-von-neumann",
"omar-al-khayyam",
"siméon-denis-poisson",
"robert-hooke",
"george-washington-carver",
"niels-bohr",
"joseph-louis-gay-lussac"];

const generateName = (sep) => {
    const s = sep === undefined ? '-' : sep
    const getIndex = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }
    // const getName = () => {
    //
    // }
    const adjIdx = getIndex(0, adjs.length);
    const namesIdx = getIndex(0, names.length);
    return `${adjs[adjIdx]}${s}${names[namesIdx]}`;
}

export default generateName