import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useAuthState} from 'react-firebase-hooks/auth';
import {useCollectionData, useCollection} from 'react-firebase-hooks/firestore';

import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import InsightsIcon from '@mui/icons-material/Insights';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownloadIcon from '@mui/icons-material/Download';
import ListItemText from '@mui/material/ListItemText';
import CloudIcon from '@mui/icons-material/Cloud';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import Skeleton from '@mui/material/Skeleton';
import {collection} from "firebase/firestore";
import {Genotate} from "../components/genotate/genotate";
import {Genesight} from "../components/genesight/genesight";
import Job from '../components/job/job'
import Studies from '../components/study/studies';
import Study from '../components/study/study';
import Help from '../components/help'
import {Loading} from '../components/loading';
import AsgardErrorDialog from '../components/asgardErrorDialog';
import NewStudyForm from '../components/newStudyForm/newStudyForm.js';
import {auth, db, logout, updateUser, studiesCollection, jobsCollection} from '../functions/firebaseFunctions';
import {Downloads} from "../components/downloads";
import AlertDialog from "../components/alertDialog";

// const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);

const drawerWidth = 200;
const welcomeBack = "Welcome back to the Bifröst"
const welcomeFirstVisit = `Welcome to the Bifröst, aTyr's next gen data analytics platform`

const HomeComponent = ({user, studies}) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [submitSuccessful, setSubmitSuccessful] = useState(true);
    const [firstName, lastName] = user.displayName.split(" ")
    return (
        <Box sx={{
            flexGrow: 1,
            padding: 2
        }}>
            <Toolbar/>
            {!formOpen && formSubmitted && <AlertDialog
                open={true}
                title="Job submitted"
                severity={submitSuccessful ? "success" : "error"}
                text={submitSuccessful ?
                    "Job submitted successfully"
                    :
                    "Error in job submission"
                }
            />
            }
            <Typography variant="h4">Hello {firstName}!{' '}
                <span role="img" aria-label="welcome">😁</span>
            </Typography>
            <Typography variant="h5" sx={{mt: 1}}>
                {user.firstLogin ? welcomeFirstVisit : welcomeBack}{' '}
                <span role="img" aria-label="welcome">🔬🧪🧬</span>
            </Typography>
            <br/>
            <Typography variant="body1" gutterBottom>
                What would you like to do?
            </Typography>
            <NewStudyForm
                studies={studies}
                user={user}
                setFormSubmitted={setFormSubmitted}
                setFormOpen={setFormOpen}
                setSubmitSuccessful={setSubmitSuccessful}
            />
        </Box>
    );
}

function Home(props) {
    const el = props.el !== undefined ? props.el : "home";
    const [user, uloading, error] = useAuthState(auth);
    const [userWithMeta, setUserWithMeta] = useState({});
    const [studies, sloading, serror] = useCollectionData(collection(db, studiesCollection));
    const [jobs, jloading, jerror] = useCollection(collection(db, jobsCollection))
    const [component, setComponent] = useState(el);
    const [loading, setLoading] = useState(true);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState("");
    const navigate = useNavigate();
    const {studyId} = useParams();
    const components = [{name: "home", route: "/", icon: <HomeIcon/>, label: "Home"}, {
        name: "job",
        route: "/jobs",
        icon: <FormatListBulletedIcon/>,
        label: "Jobs"
    }, {
        name: "studies",
        route: "/studies",
        icon: <AnalyticsOutlinedIcon/>,
        label: "Studies"
    }, {
        name: "genesight",
        route: "/genesight",
        icon: <InsightsIcon/>,
        label: "Genesight"
    }, {
        name: "genotate",
        route: "/genotate",
        icon: <AutoFixHighIcon/>,
        label: "Genotate"
    },  {
        name: "downloads",
        route: "/downloads",
        icon: <DownloadIcon/>,
        label: "Downloads"
    }, {
        name: "help",
        route: "/help",
        icon: <HelpIcon/>,
        label: "Help"
    }, {
        name: "logout",
        route: "/logout",
        icon: <ExitToAppIcon/>,
        label: "Logout",
        onClick: logout
    }];

    auth.onAuthStateChanged(function (user) {
        if (!user) {
            navigate("/login");
        } else if (user) {
            updateUser(user).then((userInfo) => {
                user['firstLogin'] = userInfo.firstLogin;
                user['isAdmin'] = userInfo.isAdmin;
                setUserWithMeta(user);
            });
        }
    })

    if (process.env.NODE_ENV === "development") {
        console.log(user);
    }

    React.useEffect(() => {
        if (sloading || jloading) {
            setLoading(true)
        }
        if (!sloading && !jloading) {
            setLoading(false)
        }

    }, [sloading, jloading]);

    const handleComponentChange = (route, comp) => {
        navigate(route);
        setComponent(comp);
    }

    return (
        <Box sx={{display: "flex"}}>
            <CssBaseline/>
            <AppBar position="fixed"
                    elevation={0}
                    sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    <Typography variant="h4" noWrap sx={{ml: 1}}>
                        Bifröst
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginLeft: 'auto',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Typography variant="subtitle1">
                            {uloading ? <Skeleton/> : user.email}
                        </Typography>
                        {uloading ?
                            (<Skeleton variant="circular">
                                <Avatar/>
                            </Skeleton>)
                            : (
                                <Avatar
                                    src={user.photoURL}
                                    sx={{
                                        size: "small",
                                        ml: 2
                                    }}/>
                            )}
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0
                }}
                variant="permanent"
                PaperProps={{
                    sx: {
                        width: 200
                    }
                }}
            >
                <Toolbar/>
                <List sx={{ml: 1}}>
                    {components.map((comp) => (
                        <ListItemButton key={comp.name}
                                        onClick={comp.onClick ? comp.onClick : () => handleComponentChange(comp.route, comp.name)}
                        >
                            <ListItemIcon
                                sx={{minWidth: 40}}
                            >
                                {comp.icon}
                            </ListItemIcon>
                            <ListItemText primary={comp.label}/>
                        </ListItemButton>
                    ))}
                    {/*<ListItemButton key="Home" onClick={() => handleComponentChange("/", "home")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <HomeIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Home"/>*/}
                    {/*</ListItemButton>*/}

                    {/*<ListItemButton key="Jobs" onClick={() => handleComponentChange("/jobs", "job")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <CloudIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Jobs"/>*/}
                    {/*</ListItemButton>*/}

                    {/*<ListItemButton key="Studies" onClick={() => handleComponentChange("/studies", "studies")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <BarChartIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Studies"/>*/}
                    {/*</ListItemButton>*/}

                    {/*<ListItemButton key="Genotate" onClick={() => handleComponentChange("/genotate", "genotate")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <AutoFixHighIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Genotate"/>*/}
                    {/*</ListItemButton>*/}

                    {/*<ListItemButton key="Genesight" onClick={() => handleComponentChange("/genesight", "genesight")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <InsightsIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Genesight"/>*/}
                    {/*</ListItemButton>*/}

                    {/*<ListItemButton key="Downloads" onClick={() => handleComponentChange("/downloads", "downloads")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <DownloadIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Downloads"/>*/}
                    {/*</ListItemButton>*/}

                    {/*<ListItemButton key="Help" onClick={() => handleComponentChange("/help", "help")}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <HelpIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Help"/>*/}
                    {/*</ListItemButton>*/}

                    {/*<ListItemButton key="Logout" onClick={logout}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <ExitToAppIcon/>*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Logout"/>*/}
                    {/*</ListItemButton>*/}
                </List>
            </Drawer>

            <Box sx={{width: '100%', height: '100%'}}>
                {loading && <Loading show={loading}/>}
                {!loading && showErrorAlert &&
                    <AsgardErrorDialog
                        errorMsg={errorText}
                        showError={showErrorAlert}
                        setShowErrorAlert={setShowErrorAlert}
                    />
                }
                {!loading && component === 'home' &&
                    <HomeComponent
                        user={user}
                        studies={studies}
                    />
                }
                {!loading && component === 'studies' &&
                    <Studies
                        user={user}
                        studies={studies}
                    />
                }
                {!loading && component === 'genotate' &&
                    <Genotate
                        user={user}
                    />
                }
                {!loading && component === 'genesight' &&
                    <Genesight
                        user={user}
                    />
                }
                {!loading && component === 'study' &&
                    <Study
                        user={user}
                        studies={studies}
                        jobs={jobs}
                        expanded={studyId}
                        setLoading={setLoading}
                        setShowErrorAlert={setShowErrorAlert}
                        setErrorText={setErrorText}
                    />
                }
                {!loading && component === 'job' &&
                    <Job
                        user={user}
                        jobs={jobs}
                        studies={studies}
                    />
                }
                {!loading && component === 'help' && <Help/>}
                {!loading && component === 'downloads' &&
                    <Downloads
                        user={user}
                        setLoading={setLoading}
                    />
                }
            </Box>
        </Box>
    );
}

export default Home;
